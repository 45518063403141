import { SAMPLE_STATUSES_DESC } from '@/config/constants';

const orderSamplesFilters = [
  {
    name: 'status',
    label: 'status',
    type: 'select',
    permissions: ['admin'],
    whereOperator: '=',
    options: Object.keys(SAMPLE_STATUSES_DESC)
      .map(key => ({ label: `enums.sampleStatuses.${SAMPLE_STATUSES_DESC[key]}`, value: key })),
  },
];

export default orderSamplesFilters;
