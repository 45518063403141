<template>
  <el-container>
    <MainSidebar :menu-items="menuItems" />
    <el-container direction="vertical">
      <MainHeader />
      <el-main
        :class="[
          'main',
          {'main--has-fab': !!this.$route.meta.hasFloatingActionButton},
        ]"
      >
        <MainBackground />
        <el-container>
          <router-view />
        </el-container>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { menuItems } from '@/config';
import versionApi from '@/api/version';
import MainSidebar from '@/components/MainSidebar.vue';
import MainHeader from '@/components/MainHeader.vue';
import MainBackground from '@/components/MainBackground.vue';
import store from '@/store';
import auth from '../services/auth';

export default {
  name: 'PostLogin',
  components: {
    MainSidebar,
    MainHeader,
    MainBackground,
  },
  mounted() {
    this.getVersion();
  },
  data() {
    return {
      sidebarIsCollapsed: false,
      sidebarIsCollapsedIcon: 'el-icon-s-fold',
      menuItems,
    };
  },
  methods: {
    async getVersion() {
      /**
        * @type import('axios').AxiosResponse<{ version: string, deployment: string }>
        */
      const response = await versionApi.getVersion();
      const { version, deployment } = response.data;
      await store.dispatch('setVersion', version);
      await store.dispatch('setDeployment', deployment);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // const nextRoute = vm.loggedUser.permissions ? '/admin/news-user' : '/admin/offers';
      const nextRoute = vm.loggedUser.permissions.includes('admin') ? '/admin/offers' : '/admin/news-user';
      if (from.name === 'Login') next(nextRoute);
    });
  },
  computed: {
    ...mapGetters([
      'loggedUser',
    ]),
  },
  watch: {
    loggedUser(newVal) {
      if (newVal === null) {
        auth.logout();
        this.$router.push({ name: 'Login' });
        this.$notify(
          { showClose: true, type: 'success', message: this.$t('logout.successMessage') },
        );
      }
    },
  },
};
</script>

<style lang="scss">
.main {
  &--has-fab {
    margin-bottom: 100px;
  }
}
</style>
