<template>
  <el-tooltip
    :content="tooltipContent"
    placement="top"
  >
    <i class="fas fa-question info-tooltip__icon" />
  </el-tooltip>
</template>

<script>

export default {
  name: 'InfoTooltip',
  props: {
    tooltipContent: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
.el-tooltip__popper {
  max-width: 400px;
}
.info-tooltip {
  &__icon {
    background-color: $--color-info;
    color: $--color-white;
    line-height: 20px;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 10px;
    border-radius: 50%;
  }
}
</style>
