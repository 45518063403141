<template>
  <el-header
    class="main-header"
    height="60px"
  >
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
      class="main-header__row"
    >
      <el-col>
        <h1 class="title">
          {{ $t(
            $route.meta.sectionTitle ||
              $router.resolve({ name: $route.meta.menuActiveRouteName }).resolved.meta.sectionTitle
          ) }}
          <span
            v-if="this.$route.meta.sectionInfoLabel"
            class="title__info"
          >
            <InfoTooltip :tooltip-content="$t(this.$route.meta.sectionInfoLabel)" />
          </span>
        </h1>
      </el-col>
      <el-col>
        <el-row
          type="flex"
          justify="end"
          align="middle"
        >
          <el-col class="header-user header-user--name">
            <el-link
              v-if="loggedUser"
              type="primary"
            >
              {{ `${$t('header.welcome')} ${loggedUser.givenName} ${loggedUser.familyName}` }}
            </el-link>
          </el-col>

          <el-col class="header-user header-user--name">
            <el-select
              v-model="locale"
              :placeholder="$t(`${'global.lang'}`)"
              style="max-width: 6rem"
              @change="setLocale"
            >
              <el-option
                v-for="(option) in lang"
                :key="option.value"
                :label="$t(`${option.label}`)"
                :value="option.value"
              >
                <span>{{ $t(`${option.label}`) }}</span>
              </el-option>
            </el-select>
          </el-col>
          <el-col class="header-user header-user--logout">
            <el-link
              type="primary"
              @click.prevent="setLoggedUser(null)"
            >
              {{ $t('logout.label') }}
            </el-link>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { localize } from 'vee-validate/dist/vee-validate.full.esm';
import storage from 'store';

import InfoTooltip from '@/components/InfoTooltip.vue';

export default {
  name: 'MainHeader',
  components: {
    InfoTooltip,
  },
  data() {
    return {
      locale: storage.get('locale') || 'it',
      lang: [
        { label: 'global.italian', value: 'it' },
        { label: 'global.spanish', value: 'es' },
        { label: 'global.english', value: 'en' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'loggedUser',
    ]),
  },
  methods: {
    ...mapActions([
      'setLoggedUser',
    ]),
    setLocale() {
      this.$root.$i18n.locale = this.locale;
      localize(this.locale);
      storage.set('locale', this.locale);
    },
  },
};
</script>

<style lang="scss">
  .main-header {
    border-bottom: solid 1px #e6e6e6;
    background-color: #F4F4F4;

    &__row {
      height: 100%;

      .header-user {
        flex: 0 0 auto;
        width: auto;
        padding: 0 20px;

        &--logout {
          text-transform: uppercase;
        }
      }
    }

    .title {
      font-size: $--font-size-medium;
      font-weight: $--font-weight-primary;
      text-transform: uppercase;
      color: $--color-primary;
    }
  }
</style>
