import tableHelper from '@/services/tableHelper';
import http from '../services/http';
import { ordersFilters, orderSamplesFilters } from '../config';
import { SAMPLE_STATUSES } from '../config/constants';

const orders = {
  index(tableFilters, filterStatus) {
    const filters = tableHelper.whereFilters(filterStatus, ordersFilters);
    // const includeCustomer = { relation: 'customer' };
    try {
      return http.request({
        method: 'get',
        url: '/order-list',
        params: { filter: { ...tableFilters, where: filters } },
      });
    } catch (e) {
      throw e;
    }
  },
  show(id) {
    try {
      return http.request({
        method: 'get',
        url: `/orders/${id}`,
      });
    } catch (e) {
      throw e;
    }
  },
  findOrdersByTitle(title) {
    try {
      const filter = {
        where: {
          title: { regexp: `/${title}/i` },
          limit: 10,
        },
      };
      return http.request({
        method: 'get',
        url: '/orders',
        params: { filter },
      });
    } catch (e) {
      throw e;
    }
  },
  create(orderParams) {
    try {
      return http.request({
        method: 'post',
        url: '/orders',
        data: orderParams,
      });
    } catch (e) {
      throw e;
    }
  },
  delete(id) {
    return http.request({
      method: 'delete',
      url: `/orders/${id}`,
    });
  },
  samplesIndex(orderId, tableFilters = {}, filterStatus) {
    const filters = filterStatus ? tableHelper.whereFilters(filterStatus, orderSamplesFilters) : {
      status: { neq: SAMPLE_STATUSES.CANCELED },
    };
    try {
      return http.request({
        method: 'get',
        url: `/orders/${orderId}/samples`,
        params: { filter: { ...tableFilters, where: filters } },
      });
    } catch (e) {
      throw e;
    }
  },
  patchOrder(orderId, orderParams) {
    try {
      return http.request({
        method: 'patch',
        url: `/orders/${orderId}`,
        data: orderParams,
      });
    } catch (e) {
      throw e;
    }
  },
  samplesCount(orderId, filterStatus) {
    const where = tableHelper.whereFilters(filterStatus, orderSamplesFilters);
    return http.request({
      method: 'get',
      url: `/orders/${orderId}/samples/count`,
      params: { where },
    });
  },
  importOrderSamples(orderId, data) {
    try {
      return http.request({
        method: 'post',
        url: `/orders/${orderId}/samples/import`,
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        data,
      });
    } catch (e) {
      throw e;
    }
  },
  createSample(orderId, sampleParams) {
    try {
      return http.request({
        method: 'post',
        url: `/orders/${orderId}/samples`,
        data: sampleParams,
      });
    } catch (e) {
      throw e;
    }
  },
  patchSample(orderId, sampleId, sampleParams) {
    try {
      return http.request({
        method: 'patch',
        url: `/orders/${orderId}/samples/${sampleId}`,
        data: sampleParams,
      });
    } catch (e) {
      throw e;
    }
  },
  patchSamples(orderId, data, whereCondition) {
    return http.request({
      method: 'patch',
      url: `/orders/${orderId}/samples`,
      params: { where: whereCondition },
      data,
    });
  },
  showSample(orderId, sampleId) {
    try {
      return http.request({
        method: 'get',
        url: `/orders/${orderId}/samples/${sampleId}`,
      });
    } catch (e) {
      throw e;
    }
  },
  deleteSample(orderId, sampleId) {
    try {
      return http.request({
        method: 'delete',
        url: `/orders/${orderId}/samples/${sampleId}`,
      });
    } catch (e) {
      throw e;
    }
  },
  cloneSample(orderId, sampleId) {
    return http.request({
      method: 'post',
      url: `/orders/${orderId}/samples/${sampleId}/clone`,
    });
  },
  update(id, orderParams) {
    try {
      return http.request({
        method: 'put',
        url: `/orders/${id}`,
        data: orderParams,
      });
    } catch (e) {
      throw e;
    }
  },
  patch(id, orderParams) {
    try {
      return http.request({
        method: 'patch',
        url: `/orders/${id}`,
        data: orderParams,
      });
    } catch (e) {
      throw e;
    }
  },
  count(filterStatus) {
    const where = tableHelper.whereFilters(filterStatus, ordersFilters);
    try {
      return http.request({
        method: 'get',
        url: '/orders/count',
        params: { where },
      });
    } catch (e) {
      throw e;
    }
  },
  exportExcel(orderId) {
    return http.request({
      method: 'get',
      url: `/orders/${orderId}/export`,
      responseType: 'blob',
    });
  },
  exportRawData(orderId) {
    return http.request({
      method: 'get',
      url: `/orders/${orderId}/raw-data-report`,
      responseType: 'blob',
    });
  },
  getFinalCustomerList() {
    return http.request({
      method: 'get',
      url: '/final-customers',
    });
  },
};

export default orders;
