import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

// modules
import users from './modules/users';
import filters from './modules/filters';
import tables from './modules/tables';
import version from './modules/version';


Vue.use(Vuex);

export default new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    users,
    filters,
    tables,
    version,
  },
});
