const customersFilters = [
  {
    name: 'id',
    label: 'customerCode',
    type: 'number',
    whereOperator: '=',
  },
  {
    name: 'name',
    label: 'customerName',
    type: 'text',
    whereOperator: 'regexp',
  },
  // {
  //   name: 'address',
  //   label: 'address',
  //   type: 'text',
  //   whereOperator: 'regexp',
  // },
  {
    name: 'vat',
    label: 'vat',
    type: 'number',
    min: 0,
    max: false,
    whereOperator: 'regexp',
  },
  {
    name: 'city',
    label: 'city',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'isEnabled',
    label: 'activeInactive',
    type: 'select',
    whereOperator: '=',
    options: [
      { label: 'filters.active', value: 1 },
      { label: 'filters.inactive', value: 0 },
    ],
  },
];

export default customersFilters;
