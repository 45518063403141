import { NEWS_STATUSES_DESC } from '@/config/constants';

const newsColumns = {
  publishedAt: {
    key: 'publishedAt',
    label: 'publishedAt',
    type: 'date',
    sortable: 'custom',
  },
  title: {
    key: 'title',
    label: 'newsTitle',
    type: 'text',
    sortable: 'custom',
  },
  status: {
    key: 'status',
    label: 'status',
    type: 'encoded',
    sortable: 'custom',
    customClass: true,
    enum: 'newsStatuses',
    decode: value => NEWS_STATUSES_DESC[value],
    getClassModifier: value => NEWS_STATUSES_DESC[value],
    minWidth: 50,
  },
  actions: {
    label: 'actions',
    type: 'actions',
    sortable: false,
    minWidth: 80,
    buttons: [
      {
        name: 'editNews',
        icon: 'fas fa-pencil',
        color: 'blue-light',
      },
      {
        name: 'deleteNews',
        icon: 'fas fa-trash-alt',
        color: 'red',
      },
    ],
  },
};

export default newsColumns;
