import moment from 'moment';

import tableHelper from '@/services/tableHelper';
import http from '../services/http';
import offerItemsFilters from '../config/filters/offer_items';
import { offersFilters, sampleAnalysesFilters } from '../config';

const generateCustomerIdFilter = customerId => (
  tableHelper.whereFilters({
    customerId: [{
      key: `${customerId}`,
      label: `${customerId}`,
    }],
  }, [{
    name: 'customerId',
    type: 'text',
    whereOperator: '=',
  }])
);

const offers = {
  index(tableFilters, filterStatus, expirationFilter = false) {
    const filters = tableHelper.whereFilters(filterStatus, offersFilters);
    // const includeCustomer = [{ relation: 'customer' }];
    const where = expirationFilter
      ? {
        or: [{ expirationDate: { gte: moment.utc().startOf('day').toDate() } }, { expirationDate: { eq: null } }],
        ...filters,
      }
      : filters;
    try {
      return http.request({
        method: 'get',
        url: '/offers',
        params: { filter: { ...tableFilters, where } },
      });
    } catch (e) {
      throw e;
    }
  },
  count(filterStatus, expirationFilter = false) {
    const filters = tableHelper.whereFilters(filterStatus, offersFilters);
    const where = expirationFilter
      ? {
        or: [{ expirationDate: { gte: moment.utc().startOf('day').toDate() } }, { expirationDate: { eq: null } }],
        ...filters,
      }
      : filters;
    try {
      return http.request({
        method: 'get',
        url: '/offers/count',
        params: { where },
      });
    } catch (e) {
      throw e;
    }
  },
  findOffersByCustomer(customerId) {
    try {
      const filter = {
        where: { customerId },
      };
      return http.request({
        method: 'get',
        url: '/customers',
        params: { filter },
      });
    } catch (e) {
      throw e;
    }
  },
  findOffersByDocNumber(docNumber) {
    try {
      const filter = {
        where: {
          docNumber: { regexp: `/${docNumber}/i` },
          limit: 10,
        },
      };
      return http.request({
        method: 'get',
        url: '/offers',
        params: { filter },
      });
    } catch (e) {
      throw e;
    }
  },
  show(id) {
    try {
      return http.request({
        method: 'get',
        url: `/offers/${id}`,
      });
    } catch (e) {
      throw e;
    }
  },
  createOffer(offerParams) {
    try {
      return http.request({
        method: 'post',
        url: '/offers',
        data: offerParams,
      });
    } catch (e) {
      throw e;
    }
  },
  createOfferRequest(requestParams) {
    try {
      return http.request({
        method: 'post',
        url: '/ask-offer',
        data: requestParams,
      });
    } catch (e) {
      throw e;
    }
  },
  updateOffer(id, offerParams) {
    try {
      return http.request({
        method: 'put',
        url: `/offers/${id}`,
        data: offerParams,
      });
    } catch (e) {
      throw e;
    }
  },
  patchOffer(id, offerParams) {
    try {
      return http.request({
        method: 'patch',
        url: `/offers/${id}`,
        data: offerParams,
      });
    } catch (e) {
      throw e;
    }
  },
  itemsIndex(id, tableFilters, filterStatus) {
    const filters = tableHelper.whereFilters(filterStatus, offerItemsFilters);
    try {
      return http.request({
        method: 'get',
        url: `/offers/${id}/offer-items`,
        params: { filter: { ...tableFilters, where: filters, include: [{ relation: 'analysis', scope: { include: [{ relation: 'children' }] } }] } },
      });
    } catch (e) {
      throw e;
    }
  },
  itemsCount(id, filterStatus) {
    const filters = tableHelper.whereFilters(filterStatus, offerItemsFilters);
    try {
      return http.request({
        method: 'get',
        url: `/offers/${id}/offer-items`,
        params: { filter: { where: filters } },
      });
    } catch (e) {
      throw e;
    }
  },
  getOfferPdf(id) {
    try {
      return http.request({
        method: 'get',
        url: `/offers/${id}/attachment`,
        responseType: 'blob',
      });
    } catch (e) {
      throw e;
    }
  },
  importOfferItems(id, data) {
    try {
      return http.request({
        method: 'post',
        url: `/offers/${id}/offer-items/import`,
        headers: {
          'Content-Type': 'text/csv',
        },
        data,
      });
    } catch (e) {
      throw e;
    }
  },
  getOfferItemList(language, tableFilters, filterStatus, customerId = null) {
    const initialFilters = tableHelper.whereFilters(filterStatus, sampleAnalysesFilters);
    const customerFilter = generateCustomerIdFilter(customerId);
    const filters = {
      and: customerFilter.and.concat(initialFilters.and || []),
    };
    try {
      return http.request({
        method: 'get',
        url: '/offer-item-list',
        params: { filter: { ...tableFilters, where: filters } },
        headers: {
          'Content-Language': language,
        },
      });
    } catch (e) {
      throw e;
    }
  },
  getOfferItemListCount(filterStatus, customerId = null) {
    const initialFilters = tableHelper.whereFilters(filterStatus, sampleAnalysesFilters);
    const customerFilter = generateCustomerIdFilter(customerId);
    const filters = {
      and: customerFilter.and.concat(initialFilters.and || []),
    };
    try {
      return http.request({
        method: 'get',
        url: '/offer-item-list/count',
        params: { where: filters },
      });
    } catch (e) {
      throw e;
    }
  },
};

export default offers;
