// Root

export const SET_READY = 'SET_READY';
export const SET_LOADING = 'SET_LOADING';

// users
export const SET_USER = 'SET_USER';

// tables
export const UPDATE_TABLES_STATUS = 'UPDATE_TABLES_STATUS';
export const UPDATE_TABLE_SORTING = 'UPDATE_TABLE_SORTING';
export const UPDATE_TABLE_COUNT = 'UPDATE_TABLE_COUNT';
export const UPDATE_TABLE_CURRENT_PAGE = 'UPDATE_TABLE_CURRENT_PAGE';
export const UPDATE_TABLE_PER_PAGE = 'UPDATE_TABLE_PER_PAGE';
export const UPDATE_TABLE_READY = 'UPDATE_TABLE_READY';
export const UPDATE_TABLE_ROWS = 'UPDATE_TABLE_ROWS';

// Filters
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

// Version
export const SET_VERSION = 'SET_VERSION';
export const SET_DEPLOYMENT = 'SET_DEPLOYMENT';
