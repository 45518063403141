import { customersApi } from '@/api';

const offersFilters = [
  {
    name: 'docNumber',
    label: 'docNumber',
    help: 'offer.help.filter.admin.docNumber',
    type: 'text',
    whereOperator: 'regexp',
    // permissions: ['admin'],
  },
  {
    name: 'issuedAtLocal',
    label: 'issuedAt',
    type: 'dateString',
    whereOperator: '=',
    permissions: ['admin'],
  },
  {
    name: 'customerId',
    label: 'customerName',
    type: 'autocomplete',
    autocompleteSearch: async (queryString, cb) => {
      const customersResponse = await customersApi.findCustomersByName(queryString);
      const filteredResults = customersResponse.data.map(item => (
        {
          label: item.name,
          value: item.id,
        }
      ));
      // call callback function to return suggestions
      cb(filteredResults);
    },
    whereOperator: '=',
    permissions: ['admin'],
  },
  {
    name: 'enabled',
    label: 'activeInactive',
    type: 'select',
    whereOperator: '=',
    options: [
      { label: 'filters.enabled', value: 'true' },
      { label: 'filters.disabled', value: 'false' },
    ],
  },
  {
    name: 'type',
    label: 'type',
    type: 'select',
    whereOperator: '=',
    options: [
      { label: 'enums.offerType.list', value: 'list' },
      { label: 'enums.offerType.quantity', value: 'quantity' },
      { label: 'enums.offerType.brackets', value: 'brackets' },
    ],
    permissions: ['user'],
  },
];

export default offersFilters;
