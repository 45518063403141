<template>
  <el-menu
    class="menubar"
    :collapse="sidebarIsCollapsed"
    :router="true"
    :default-active="this.$route.meta.menuActiveRouteName || this.$route.name"
  >
    <el-menu-item
      v-for="(menuItemPermitted, index) in menuItemsPermitted"
      :key="index"
      :index="menuItemPermitted.name"
      :route="{name: menuItemPermitted.name}"
      class="menu-item"
    >
      <i :class="`menu-item__icon ${menuItemPermitted.icon}`" />
      <span slot="title">{{ $t(`menuItems.${menuItemPermitted.name}`) }}</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
import acl from '../services/acl';

export default {
  name: 'SidebarMenu',
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
    sidebarIsCollapsed: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      menuItemsPermitted: [],
    };
  },
  created() {
    // filter menuItems by permissions
    this.menuItemsPermitted = this.menuItems.filter(
      menuItem => acl.userCanUse(
        this.$router.resolve({ name: menuItem.name }).resolved.meta.routePermissions,
        true,
      ),
    );
  },
};
</script>

<style lang="scss">
  .menubar {
    &.el-menu {
      border-right: 0;
    }

    &:not(.el-menu--collapse) {
      width: 230px;
    }

    .menu-item {
      padding: 0 20px 0 20px;
      color: $--color-white;
      border-bottom: 1px solid #62798D;

      &.is-active {
        background-color: $--color-white;
        color: $--color-primary;
      }
      &:hover, &:focus {
        color: $--color-primary;

        .menu-item__icon {
          color: $--color-primary;
        }
      }

      &__icon {
        color: $--color-white;
        margin-right: 5px;
        width: 24px;
        text-align: center;
        font-size: 18px;
        vertical-align: middle;
        transition: border-color .3s, background-color .3s, color .3s;
      }
    }
  }
</style>
