import http from '../services/http';

const orders = {
  async getSamplesTemplate() {
    return http.request(
      {
        method: 'get',
        url: '/download/sample-template-xlsx',
        responseType: 'blob',
      },
    );
  },
};

export default orders;
