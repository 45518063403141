
import { ANALYSIS_STATUSES_DESC } from '@/config/constants';

const orderSampleAnalysesFilters = [
  {
    name: 'status',
    label: 'status',
    type: 'select',
    whereOperator: '=',
    options: Object.keys(ANALYSIS_STATUSES_DESC)
      .map(key => ({ label: `enums.analysisStatuses.${ANALYSIS_STATUSES_DESC[key]}`, value: key })),
  },
];

export default orderSampleAnalysesFilters;
