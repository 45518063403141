import storage from 'store';
import store from '../store';

export default {
  logout() {
    storage.remove('authToken');
    storage.remove('refreshToken');
    // Resets store status
    store.dispatch('resetStore');
  },
  authTokenExists() {
    return !!storage.get('authToken');
  },
};
