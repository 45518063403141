
import { ANALYSIS_STATUSES_DESC } from '@/config/constants';

const orderSampleAnalysesColumns = {
  analysisDescription: {
    key: 'analysis.description',
    label: 'description',
    type: 'text',
    sortable: 'custom',
  },
  status: {
    key: 'status',
    label: 'status',
    type: 'encoded',
    sortable: 'custom',
    customClass: true,
    enum: 'analysisStatuses',
    decode: value => ANALYSIS_STATUSES_DESC[value],
    getClassModifier: value => ANALYSIS_STATUSES_DESC[value],
    minWidth: 20,
  },
  actions: {
    key: 'actions',
    label: 'actions',
    type: 'actions',
    sortable: false,
    minWidth: 50,
    buttons: [
      {
        name: 'downloadExcel',
        icon: 'fas fa-file-excel',
        color: 'sand',
        permissions: ['admin', 'ordersManage'],
      },
    ],
  },
};

export default orderSampleAnalysesColumns;
