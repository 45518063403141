export default {
  global: {
    continue: 'Continúa',
    cancel: 'Cancela',
    back: 'Atrás',
    warning: '¡Atención!',
    notPdfFile: '¡Carga un archivo pdf válido!',
    browse: 'Navega',
    notCsvFile: 'Carga un archivo cvs válido',
    fileExceeded: 'Carga un archivo de tamaño <{0} Mb',
    noMultipleFilesUpload: '¡Necesitas eliminar los archivos previos antes de poder cargar uno nuevo!',
    uploadFilesLimitExceeded: 'Se ha alcanzado el número máximo de archivos que se pueden cargar.',
    confirmUploadCancel: '¿Estás seguro que quieres eliminar el archivo?',
    backToList: 'Volver a la lista',
    edit: 'Editar',
    select: 'Seleccionar',
    add: 'Añadir',
    apply: 'Aplicar',
    lang: 'Lengua',
    italian: 'Italiano',
    english: 'Inglés',
    spanish: 'Español',
    attachment: 'Adjunto',
    removeSwitchPdf: 'Reemplazar / Eliminar PDF',
    uploadPdf: 'Subir PDF',
    downloadPdf: 'Descargar PDF',
    help: 'Ayuda',
    connectionError: '¡Error de comunicación con el servidor!',
    requiredFields: 'Los campos marcados con * son obligatorios',
  },
  permissions: {
    title: 'Permisos',
    admin: 'Super Admin',
    news: 'News',
    newsView: 'Visualización',
    newsManage: 'Administración',
    offers: 'Ofertas',
    offersView: 'Visualización',
    offersManage: 'Administración',
    orders: 'Ordenes',
    ordersView: 'Visualización',
    ordersManage: 'Administración',
    samplesStatus: 'Estado de las muestras',
    samplesStatusView: 'Visualización',
    samplesStatusManage: 'Administración',
    billing: 'Facturación',
    billingView: 'Visualización',
    billingManage: 'Administración',
    users: 'Usuarios',
    usersView: 'Visualización',
    usersManage: 'Administración',
    customers: 'Empresas',
    customersView: 'Visualización',
    customersManage: 'Administración',
  },
  paneContent: {
    testLabel: 'test',
  },
  common: {
    noPermissonRoute: '¡No tienes permiso para acceder a esta página!',
  },
  table: {
    massiveActions: {
      title: 'Solicitar estado a todos',
      submit: 'Solicitar',
    },
  },
  header: {
    welcome: 'Bienvenido',
    titles: {
      offersIndex: 'Lista de ofertas',
      usersIndex: 'Lista de usuarios',
      analysesIndex: 'Lista de test',
      analysisCreate: 'Crea test / Cierra',
      analysisEdit: 'Modifica test',
      newOffer: 'Crea oferta',
      editOffer: 'Modifica oferta',
      showOffer: 'Oferta',
      customersIndex: 'Cliente',
      customersEdit: 'Modifica cliente',
      ordersIndex: 'Lista de pedidos',
      editOrder: 'Modifica pedido',
      editOrderSample: 'Modifica muestra',
    },
  },
  tooltips: {
    offersIndex: 'Lista de ofertas válidas',
    requestOffer: 'En esta pantalla puedes solicitar la emisión de una oferta caducada o de un nuevo tipo de test',
    ordersIndex: 'En esta pantalla puedes realizar y monitorear tus pedidos',
    samplesIndex: 'En esta pantalla puedes monitorear tus muestras y descargar los resultados',
    analysesIndex: 'En esta pantalla puedes monitorear el estado de tus análisis de la muestra',
    orderName: 'Asigna un nombre a tu pedido',
    customerOrderNumber: 'Introducir tu referencia interna de pedido para que aparezca correctamente en tu factura',
    massiveXlsOrder: 'Rellena el documento Excel siguiendo las instrucciones que contiene y utilízalo para introducir varias muestras al mismo tiempo',
    remainingAnalyses: 'Número de tests pendientes. Válido sólo para abonos y tramos',
    newSample: {
      labSampleCode: 'Código artículo de la muestra',
      productName: 'Nombre de la muestra (obligatorio)',
      customerSampleCode: 'Código muestra del cliente',
      lot: 'Lote de la muestra',
      lotDate: 'Data di producción del lote',
      sector: 'Sector de la muestra (obligatorio - seleccionar de la lista)',
      lotNumber: 'Número de bultos de la muestra (obligatorio)',
      note: 'Notas del cliente (presente en el Informe del análisi)',
      finalCustomer: 'Cliente final che encarga el test de la muestra',
      finalCustomerRef: 'Ref. del Cliente final que ha encargado el test de la muestra',
      description: 'Descripción de la muestra (seleccionar de la lista)',
      comments: 'Notas adicionales (no presentes en el Informe del test)',
      costCenter: 'Centro de coste del cliente',
      pdfUpload: 'Carga archivos que contenga todos los documentos que quieres comunicar a Complife para una correcta ejecución del test  (p.ej. ficha técnica) o que son requeridos para la realización del test.(p.ej INCI)',
    },
  },
  menuItems: {
    usersIndex: 'Usuarios',
    analysesIndex: 'Tests',
    offersIndex: 'Ofertas',
    requestOffer: 'Solicitar oferta',
    customersIndex: 'Clientes',
    ordersIndex: 'Pedidos',
    samplesIndex: 'estado de las muestras',
    newsAdminIndex: 'Noticias',
    newsUserIndex: 'Noticias',
  },
  loginView: {
    usernameInput: 'Usuario',
    passwordInput: 'Contraseña',
    passwordForgotLabel: '¿Contraseña olvidada?',
    accessButton: 'Acceder',
    title: 'Tu',
    titleBlue: 'laboratorio',
  },
  passwordResetView: {
    title: 'Introduce tu nueva contraseña',
    passwordInput: 'Contraseña',
    confirmPasswordInput: 'Confirma contraseña',
    submitButton: 'Enviar',
    passwordChangedMessage: 'Contraseña modificada correctamente',
  },
  passwordRecoverView: {
    title: 'Recupera tu contraseña',
    emailInput: 'Email',
    submitButton: 'Enviar',
    passwordRecoveredMessage: 'Instrucciones adicionales han sido enviadas a tu dirección de correo electrónico',
  },
  logout: {
    label: 'Salir',
    successMessage: 'Ha salido correctamente',
  },
  users: {
    create: {
      givenName: 'Nombre',
      familyName: 'Apellido',
      email: 'Email',
      emailConfirmation: 'Confirma Email',
      password: 'Contraseña',
      passwordConfirmation: 'Confirma contraseña',
      phone: 'Teléfono',
      createUser: 'Crear usuario',
      abortCreate: 'Borrar',
      success: 'Usuario creado correctamente',
      customer: 'Nombre de usuario',
      role: 'Cargo',
      function: 'Función',
      functions: {
        qc: 'Control de calidad',
        qa: 'Aseguramiento de calidad',
        rd: 'Investigación y desarrollo',
        production: 'Producción',
        administration: 'Administración',
        logistics: 'Logística',
        purOff: 'Oficina de compras',
      },
    },
    update: {
      success: 'Usuario correctamente actualizado',
      updateUser: 'Actualizar usuario',
      invitationSent: 'Correo de verificación enviado',
    },
  },
  customers: {
    create: {
      createCustomer: 'Crear empresa',
      name: 'Nombre',
      address: 'Dirección',
      vat: 'CIF',
      sdi: 'SDI',
      city: 'Ciudad',
      district: 'Provincia',
      phoneNumber: 'Teléfono',
      mobile: 'Teléfono móvil',
      isEnabled: 'Activo',
      internalReference: 'Onda Id',
      zip: 'Código postal',
      certifiedEmail: 'Email',
      fiscalCode: 'Código fiscal',
      abortCreate: 'Cancelar',
      success: 'Empresa creada correctamente',
      agent: 'Agente',
    },
    update: {
      updateCustomer: 'Modificar empresa',
      success: 'Empresa creada correctamente',
    },
  },
  orders: {
    orderSamples: 'Muestras',
    saveForLater: 'Guardar para después',
    print: 'Imprimir',
    send: 'Enviar',
    create: {
      createOrder: 'Crear pedido',
      title: 'Nombre del pedido',
      customer: 'Empresa',
      customerId: 'ID Empresa',
      customerOrderNumber: 'Número de pedido',
      abortCreate: 'Eliminar pedido',
      success: 'Pedido creado correctamente',
      sent: 'Pedido con ID {orderId} enviado correctamente',
      saved: 'Pedido guardado correctamente',
      confirmSend: '¿Estás seguro que quieres continuar?',
      cancelled: 'Operación cancelada',
      separateInvoice: '¿Desea que este pedido se facture por separado?',
      separateInvoiceYes: 'Sì',
      separateInvoiceNo: 'No',
    },
    update: {
      updateOrder: 'Actualizar',
      success: 'Pedido actualizado correctamente',
    },
    samples: {
      uploadReport: 'Subir informe',
      uploadReports: 'Enviar',
      abortUpload: 'Cancelar',
      importSuccess: 'Importación correcta',
      importError: 'Error de importación, verifique que el archivo tenga el formato correcto',
    },
    delete: {
      confirm: '¿Quieres cancelar el pedido?',
      success: 'pedido cancelado',
      cancelled: 'Eliminación cancelada',
    },
  },
  accreditedTestModal: {
    title: '¡Atención!',
    warning: 'Atención, test acreditado',
    description: 'Para añadir este análisis, primero tienes que completar el campo "Lote" para',
    samples: 'no muestra | otra {n} muestra | otras {n} muestras',
    next: 'Edita y ves a la próxima muestra',
    confirm: 'Acaba la adición y añade un test',
    abort: 'Cancelar',
    lot: 'Lote por muestra "{0}":',
  },
  dynamicAnalysesModal: {
    confirm: 'Finalice la edición y agregue análisis',
    abort: 'Cancelar',
    wrongNumber: '¡Seleccione {n} análisis para continuar!',
  },
  analyses: {
    index: {
      createAnalysis: 'Nuevo Test / Clase',
    },
    create: {
      formHeader: 'Nuevo Test / Clase',
      class: 'Clase',
      code: 'Código artículo',
      commodityCode: 'Departamento',
      statisticCode: 'Clase estadística',
      natureCode: 'Código tipo',
      description: 'Descripción',
      extraDescription: 'Descripción extra',
      commodityCategory: 'Descripción códigos departamento',
      statisticCategory: 'Descripción categoría estadística',
      nature: 'Descripción código tipo',
      brandCode: 'Código técnico',
      familyCode: 'Código familia',
      departmentCode: 'Código departamento',
      methodCode: 'Código método',
      brand: 'Técnica / instrumento',
      family: 'Familia',
      department: 'Departamento',
      method: 'Descripción método',
      measure: 'Descripción medida',
      measureUnitCode: 'Unidad de medida',
      accreditedTest: 'Test acreditado',
      expirationDate: 'Fecha de caducidad artículo ',
      measureCode: 'Código medida',
      dynamicClass: 'Clase dinámica',
      fixedClass: 'Clase fija',
      singleAnalysis: 'Análisis',
      submitButton: 'Enviar',
      classType: 'Tipo de clase',
      maxAnalysesNumber: 'Número máximo de análisis',
      searchAnalyses: 'Encontrar test',
      analysisTypeFilter: 'Filtrar por tipo',
      removeAnalysis: 'Eliminar',
      success: 'Test creado correctamente',
      abortCreate: 'Cancelar',
    },
    update: {
      addToClass1: 'Agregar a una clase',
      addToClass2: 'Agregar a clase',
      formHeader: 'Modificar test',
      success: 'Test modificado correctamente',
    },
  },
  filters: {
    user: 'Usuario',
    customer: 'Cliente',
    enabled: 'Activado',
    disabled: 'Desactivado',
    name: 'Nombre',
    companyName: 'Nombre empresa',
    date: 'Fecha',
    address: 'Dirección',
    city: 'Ciudad',
    email: 'Email',
    emailAddress: 'Dirección Email',
    sector: 'Sector',
    phone: 'Teléfono',
    zipCode: 'Código postal',
    vat: 'CIF',
    activeInactive: 'Activo / Inactivo',
    active: 'Activo',
    inactive: 'Inactivo',
    fromDate: 'Desde fecha',
    toDate: 'A fecha',
    clearFilter: 'Borrar',
    givenName: 'Nombre',
    familyName: 'Apellido',
    analysisCode: 'Código test',
    customDescription: 'Descripción',
    quantity: 'Cantidad',
    applyFilter: 'Aplicar filtro',
    actions: 'Acciones',
    docNumber: 'Número documento',
    issuedAt: 'Fecha documento',
    comment: 'Comentario',
    isEnabled: 'Activado',
    isVerified: 'Verficado',
    customerName: 'Nombre empresa',
    expirationDate: 'Fecha caducidad',
    type: 'Tipo',
    description: 'Descripción',
    additionalDescription: 'Descripción adicional',
    method: 'Método',
    accreditedTest: 'Prueba acreditada',
    remaining: 'Restante',
    class: 'Clase',
    title: 'Nombre pedido',
    startDate: 'Desde fecha',
    endDate: 'Hasta fecha',
    from: 'De',
    createdAt: 'Fecha creacción',
    sentAt: 'Enviado a',
    sampleCount: 'Número muestras',
    analysisCount: 'Cuenta análisis',
    status: 'Estado',
    draft: 'Borrador',
    sent: 'Enviado',
    offer: 'Oferta',
    reportsList: 'Lista de informes',
    commodityCategory: 'Categoría producto',
    code: 'Código artículo',
    labSampleCode: 'Código muestra',
    productName: 'Nombre del producto',
    lot: 'Lote',
    order: 'Pedido',
    orderName: 'Nombre pedido',
    countryCode: 'Código idioma',
    role: 'Cargo',
    customerCode: 'Codigo del cliente',
    methodCode: 'Código de método',
    revisionNumber: 'Número de revisión',
    revisionDate: 'Fecha de revisión',
    price: 'Cantidad',
    orderNumber: 'Número de pedido',
    ondaOrderId: 'Id ONDA',
    publishedAt: 'Fecha de publicación',
    newsTitle: 'Titulo de noticias',
    childrenCount: 'Número de análisis',
    orderId: 'ID Pedido',
    sampleId: 'ID Muestra',
    rejectReason: 'Razón de la denegación',
    offerEnabled: 'Activa',
    ordersSent: 'Enviado',
    orderConfirmId: 'Número de confirmación de pedido',
    testType: 'Tipo de test',
    offerNumber: 'Número de oferta',
    customerOrderNumber: 'Número de pedido del cliente',
    function: 'Función',
    customerProductName: 'Código muestra cliente',
    sampleReportCodes: 'Códigos de los informes de ensayo',
  },
  tableButtons: {
    downloadOffer: 'Descargar oferta',
    seeDetail: 'Ver detalle',
    change: 'Modificar',
    reloadExcel: 'Recargar excel',
    disable: 'Desactivar',
    enable: 'Activar',
    disableSelected: 'Desactivar seleccionado',
    disableUser: 'Desactivar usuario',
    enableUser: 'Activar usuario',
    userOrders: 'Pedidos',
    editCustomer: 'Editar cliente',
    editUser: 'Editar usuario',
    disableCustomer: 'Desactivar cliente',
    enableCustomer: 'Activar cliente',
    editArticle: 'Editar artículo',
    disableAnalysis: 'Desactivar test',
    enableAnalysis: 'Activar test',
    addAnalysisToClass: 'Agregar a una clase',
    editOrder: 'Editar pedido',
    completeOrder: 'Completar pedido',
    editSample: 'Editar muestra',
    deleteSample: 'Borrar muestra',
    cloneSample: 'Duplicar muestra',
    showSamples: 'Mostrar muestras',
    uploadPdf: 'Subir PDF',
    changePdf: 'Reemplazar pdf',
    renameSample: 'Renombrar',
    downloadSampleReport: 'Descargar informe',
    addAnalyses: 'Añadir análisis',
    showAnalyses: 'Mostrar análisis',
    showOrderSamples: 'Mostrar muestras',
    selectOnlyThisSampleOn: 'Mostrar sólo tests asociados',
    selectOnlyThisSampleOff: 'Mostrar todos los tests',
    showUsers: 'Mostrar todos los usuarios',
    exportExcel: 'Descargar Excel',
    editNews: 'Editar noticias',
    deleteNews: 'Eliminar noticias',
    downloadExcel: 'Descargar Excel',
    uploadSampleReport: 'Subir PDF',
    deleteOrder: 'Eliminar pedido',
    sendVerificationEmail: 'Envia un correo electronico de verificación',
    exportRawData: 'Descarga de datos en borrador',
    enableRawData: 'Habilitar datos en bruto',
    disableRawData: 'Desactivar los datos en bruto',
  },
  enums: {
    commons: {
      yes: 'Si',
      no: 'No',
    },
    offerType: {
      list: 'Lista',
      quantity: 'Cantidad',
      brackets: 'Paréntesis',
      null: 'Ninguno',
    },
    enabledStatus: {
      enabled: 'Activado',
      disabled: 'Desactivado',
    },
    commodityCategory: {
      microbiological: 'Microbiológica',
      chemical: 'Química',
      vitro: 'Vitro',
    },
    sampleStatuses: {
      created: 'Creada',
      pending: 'Pendiente',
      accepted: 'Aceptada',
      inprogress: 'En curso',
      draft: 'Borrador',
      concluded: 'Cerrado',
      canceled: 'Cancelado',
      rejected: 'Rechazado',
    },
    newsStatuses: {
      published: 'Publicado',
      unpublished: 'Borrador',
    },
    orderStatuses: {
      draft: 'Borrador',
      sent: 'Enviado',
      accepted: 'Accepted',
    },
    analysisStatuses: {
      none: 'Creado',
      accepted: 'Aceptado',
      inprogress: 'En curso',
      completed: 'Completado',
      deleted: 'Borrado',
      suspended: 'Suspendido',
      pending: 'Pendiente',
    },
    functions: {
      qc: 'Control de calidad',
      qa: 'Aseguramiento de calidad',
      rd: 'Investigación y desarrollo',
      production: 'Producción',
      administration: 'Administración',
      logistics: 'Logística',
      purOff: 'Oficina de compras',
      undefined: '-',
    },
  },
  samples: {
    showReportsList: 'Mostrar todos los informes',
    delete: {
      confirm: '¿Estás seguro de que quieres borrar la muestra?',
      success: '¡Muestra cancelada!',
      cancelled: 'Borrado cancelado',
    },
    update: {
      success: 'Muestra actualizada correctamente',
      updateSample: 'Actualiza muestra',
    },
    clone: {
      confirm: '¿Seguro que quieres duplicar la muestra?',
      success: 'Muestra duplicada',
    },
    create: {
      import: 'Carga masiva XLSX',
      downloadExample: 'Download XLSX',
      success: 'Muestra creada correctamente',
      labSampleCode: 'Código producto',
      productName: 'Nombre producto',
      customerProductName: 'Código muestra cliente',
      lot: 'Lote',
      date: 'Fecha lote',
      sector: 'Sector',
      packagesNumber: 'Número de muestra',
      notes: 'Notas cliente',
      finalCustomer: 'Cliente final',
      sampleDescription: 'Descripción muestra',
      comments: 'Instrucciones textuales',
      costCenter: 'Centro coste cliente',
      abortCreate: 'Cancelar',
      createSample: 'Crear muestra',
      actions: 'Acciones',
      customerHeaderData: '¿Desea insertar los datos finales del cliente en el encabezado del informe de prueba?',
      customerHeaderDataYes: 'Sì',
      customerHeaderDataNo: 'No',
      customerAddress: 'Dirección del cliente',
      address: {
        addNew: 'Agregar nuevo',
        name: 'Nombre',
        address: 'Dirección',
        city: 'Ciudad',
        zip: 'Código postal',
        province: 'Provincia',
        phoneNumber: 'Número de teléfono',
      },
      fileSize: 'Archivos PDF con un tamaño inferior a 10 MB',
    },
    sectors: {
      foodSupplement: 'Suplemento aliemnticio',
      other: 'Otro',
      chemical: 'Químico',
      cosmetic: 'Cosmético',
      detergents: 'Detergente',
      medicalDevice: 'Dispositivo médico',
      packaging: 'Material embalaje',
      biocide: 'Biocida',
      pharmaceutical: 'Farmacéutico',
    },
    sampleDescriptions: {
      anhydrousFluid: 'Fluido anhidro',
      anhydrousPaste: 'Pasta anhidra',
      sanitaryTowelsDiapers: 'Toallitas sanitarias / Pañales',
      biphasic: 'Bifásico',
      capsule: 'Cápsula',
      tablet: 'Tableta',
      emulsion: 'Emulsión',
      liquidGel: 'Gel líquido',
      liquid: 'Líquido',
      oil: 'Aceite',
      packaging: 'Embalaje',
      patch: 'Patch',
      pressedPowder: 'Polvo compacto',
      loosePowder: 'Polvos',
      wipes: 'Toallitas',
      validation: 'Validación',
    },
  },
  offer: {
    index: {
      createOffer: 'Crear oferta',
    },
    request: {
      offer: 'Número de oferta anterior',
      message: 'Mensaje',
      privacyConsent: ' ',
      privacyConsentLabel: 'Procesamiento de Datos Personales',
      privacyConsentText: 'He leído y acepto la información relacionada con el procesamiento de datos personales de conformidad con los artículos del Reglamento de la UE 2016/679 13 y 14',
      marketingConsentText1: 'Usted nos autoriza a contactarlo y enviarle por correo electrónico información sobre nuestros servicios / productos / eventos y promociones que puedan interesarle.',
      marketingConsentText2: 'La autorización para procesar datos para este propósito no es necesaria, pero corre el riesgo de perderse algo que podría interesarle.',
      success: '¡Solicitud enviada con éxito!',
    },
    create: {
      formHeader: 'Crear oferta',
      request: '¿Quieres solicitar una oferta?',
      requestSubtitle: 'Complete los campos a continuación',
    },
    update: {
      formHeader: 'Editar oferta',
      success: 'Oferta modificada con éxito',
    },
    help: {
      filter: {
        admin: {
          docNumber: 'Número documento',
        },
      },
      field: {
        admin: {
          docNumber: 'Número documento',
        },
      },
    },
    ondaOfferId: 'ID de documento Onda',
    customer: 'Empresa',
    docNumber: 'Número del Documento',
    issuedAt: 'Fecha del documento',
    yourReference: 'Tu referencia',
    ourReference: 'Nuestra referencia',
    comment: 'Comentario',
    revisionNumber: 'Número de revisión de oferta',
    revisionDate: 'Fecha de revisión',
    pdfFileId: 'Subir PDF',
    downloadPdf: 'Descargar PDF',
    status: 'Estado',
    editSuccess: '¡Oferta modificada con éxito!',
    createError: '¡Error al crear oferta!',
    uploadCsvFile: 'Subir archivos de Excel',
    expirationDate: 'Fecha de fin de validez',
  },
  news: {
    index: {
      noRecords: 'No hay noticias',
    },
    create: {
      createNews: 'Crea noticias',
      formHeader: 'Crea noticias',
      submitButton: 'Guarda y publica noticias',
      draftSubmitButton: 'Guardar en borrador',
      publishedAt: 'Fecha de publicación',
      title: 'Titular de noticias',
      image: 'Subir imagen destacada',
      attachments: 'Subir archivos adjuntos',
      abstract: 'Texto del resumen',
      text: 'Texto de noticias',
      success: 'Noticias creadas con éxito',
      imagePlaceholder: 'Subir una imagen de 1200x400',
      isPublished: 'Publicado',
    },
    update: {
      success: 'Noticias editadas con éxito',
      bulkSuccess: 'Noticias editadas con éxito',
      formHeader: 'Editar noticias',
      draftSubmitButton: 'Guardar en borrador',
      submitButton: 'Editar noticias',
    },
    delete: {
      confirm: '¿Estás seguro de que deseas eliminar las noticias?',
      success: '¡Noticias borradas!',
      cancelled: '¡Eliminación cancelada!',
    },
    detail: {
      title: 'Detalle de noticias',
      attachments: 'Documentos adjuntos',
    },
  },
};
