import tableHelper from '@/services/tableHelper';
import http from '../services/http';
import { orderSampleAnalysesFilters, samplesFilters } from '../config';

const samples = {
  index(tableFilters, filterStatus, include = []) {
    const filters = tableHelper.whereFilters(filterStatus, samplesFilters);
    try {
      return http.request({
        method: 'get',
        url: '/samples',
        params: { filter: { ...tableFilters, where: filters, include } },
      });
    } catch (e) {
      throw e;
    }
  },
  count(filterStatus) {
    const where = tableHelper.whereFilters(filterStatus, samplesFilters);
    return http.request({
      method: 'get',
      url: '/samples/count',
      params: { where },
    });
  },
  bulkUpdate(data, whereCondition) {
    return http.request({
      method: 'patch',
      url: '/samples',
      params: { where: whereCondition },
      data,
    });
  },
  uploadSampleReport(sampleId, data) {
    return http.request({
      method: 'post',
      url: `/samples/${sampleId}/reports`,
      data,
    });
  },
  getSampleAttachments(sampleId) {
    return http.request({
      method: 'get',
      url: `/samples/${sampleId}/attachments`,
    });
  },
  uploadSamplePdf(sampleId, data) {
    return http.request({
      method: 'post',
      url: `/samples/${sampleId}/attachments`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  removeAttachedSamplePdf(sampleId, attachmentId) {
    return http.request({
      method: 'delete',
      url: `/samples/${sampleId}/attachments/${attachmentId}`,
    });
  },
  downloadAttachedSamplePdf(sampleId, attachmentId) {
    return http.request({
      method: 'get',
      url: `/samples/${sampleId}/attachments/${attachmentId}`,
      responseType: 'blob',
    });
  },
  getSampleReports(sampleId) {
    return http.request({
      method: 'get',
      url: `/samples/${sampleId}/reports`,
    });
  },
  patchSampleReport(sampleId, data, whereCondition) {
    return http.request({
      method: 'patch',
      url: `/samples/${sampleId}/reports`,
      params: { where: whereCondition },
      data,
    });
  },
  deleteSampleReport(sampleId, reportId) {
    return http.request({
      method: 'delete',
      url: `/samples/${sampleId}/reports/${reportId}`,
    });
  },
  downloadSampleReport(sampleId, reportId) {
    return http.request({
      method: 'get',
      url: `/samples/${sampleId}/reports/${reportId}/download`,
      responseType: 'blob',
    });
  },
  downloadSamplePreviewReport(sampleId) {
    return http.request({
      method: 'get',
      url: `/samples/${sampleId}/report-download-preview`,
      responseType: 'blob',
    });
  },
  sampleAnalysesIndex(sampleId, tableFilters, filterStatus) {
    const filters = tableHelper.whereFilters(filterStatus, orderSampleAnalysesFilters);
    try {
      return http.request({
        method: 'get',
        url: `/samples/${sampleId}/sample-analyses`,
        params: { filter: { ...tableFilters, where: filters } },
      });
    } catch (e) {
      throw e;
    }
  },
  sampleAnalysesCount(sampleId, filterStatus) {
    const where = tableHelper.whereFilters(filterStatus, orderSampleAnalysesFilters);
    return http.request({
      method: 'get',
      url: `/samples/${sampleId}/sample-analyses/count`,
      params: { where },
    });
  },
  orderSamplesAnalyses(orderId, data) {
    return http.request({
      method: 'post',
      url: `/orders/${orderId}/sample-analyses`,
      data,
    });
  },
  getOrderSamplesAnalyses(orderId) {
    return http.request({
      method: 'get',
      url: `/v2/orders/${orderId}/sample-analyses`,
      // data,
    });
  },
  bulkUpdateSampleAnalyses(id, data, whereCondition) {
    return http.request({
      method: 'patch',
      url: `/samples/${id}/sample-analyses`,
      params: { where: whereCondition },
      data,
    });
  },
  downloadExcel(orderId) {
    return http.request({
      method: 'get',
      url: `/orders/${orderId}/export`,
      responseType: 'blob',
    });
  },
  autocompleteFetch(key, value, exact = false) {
    const filter = {
      where: {
        [key]: exact ? value : { regexp: `/${value}/i` },
      },
    };
    return http.request({
      method: 'get',
      url: '/samples',
      params: { filter },
    });
  },
};

export default samples;
