import * as types from '../mutation-types';

const tableBaseState = {
  rows: [],
  count: 0,
  page: 1,
  perPage: 10,
  ready: false,
  sorting: {
    prop: null,
    order: null,
  },
};


const initialState = {
  tablesStatus: {
    customers: { ...tableBaseState },
    users: { ...tableBaseState },
    offers: { ...tableBaseState },
    offerItems: { ...tableBaseState },
    analyses: { ...tableBaseState },
    orders: { ...tableBaseState },
    samples: { ...tableBaseState },
    sampleAnalyses: { ...tableBaseState },
    orderSamples: { ...tableBaseState },
    orderSampleAnalyses: { ...tableBaseState },
    news: { ...tableBaseState },
    invoices: { ...tableBaseState },
  },
};

const tablesState = { ...initialState };

const getters = {
  tablesStatus: state => state.tablesStatus,
  tableParams: state => (listKey) => {
    const table = state.tablesStatus[listKey];
    return {
      sortBy: table.sorting,
      rows: table.rows,
      count: table.count,
      page: table.page,
      perPage: table.perPage,
      ready: table.ready,
    };
  },
  tableFilters: state => (listKey) => {
    const table = state.tablesStatus[listKey];
    return {
      order: (table.sorting.order && table.sorting.prop) ? [`${table.sorting.prop}  ${table.sorting.order === 'descending' ? 'desc' : 'asc'}`] : '',
      offset: (table.page - 1) * table.perPage,
      limit: table.perPage,
    };
  },
};

const actions = {
  updateTablesStatus({ commit }, { tableKey, name, value }) {
    commit(types.UPDATE_TABLES_STATUS, { tableKey, name, value });
  },
  updateTableSorting({ commit }, { tableKey, sorting }) {
    commit(types.UPDATE_TABLE_SORTING, { tableKey, sorting });
  },
  updateTableCurrentPage({ commit }, { tableKey, page }) {
    commit(types.UPDATE_TABLE_CURRENT_PAGE, { tableKey, page });
  },
  updateTablePerPage({ commit }, { tableKey, perPage }) {
    commit(types.UPDATE_TABLE_PER_PAGE, { tableKey, perPage });
  },
  updateTableCount({ commit }, { tableKey, count }) {
    commit(types.UPDATE_TABLE_COUNT, { tableKey, count });
  },
  updateTableRows({ commit }, { tableKey, rows }) {
    commit(types.UPDATE_TABLE_ROWS, { tableKey, rows });
  },
  updateTableReady({ commit }, { tableKey, ready }) {
    commit(types.UPDATE_TABLE_READY, { tableKey, ready });
  },
  resetTable({ dispatch }, tableKey) {
    dispatch('updateTableRows', { tableKey, rows: [] });
    dispatch('updateTableCount', { tableKey, count: 0 });
    dispatch('updateTableCurrentPage', { tableKey, page: 1 });
    dispatch('updateTablePerPage', { tableKey, perPage: 10 });
    dispatch('updateTableReady', { tableKey, ready: false });
    dispatch('updateTableSorting', { tableKey, sorting: { prop: null, order: null } });
  },
  resetAllTables({ dispatch }) {
    Object.keys(tablesState.tablesStatus).forEach((tableKey) => {
      dispatch('updateTableRows', { tableKey, rows: [] });
      dispatch('updateTableCount', { tableKey, count: 0 });
      dispatch('updateTableCurrentPage', { tableKey, page: 1 });
      dispatch('updateTablePerPage', { tableKey, perPage: 10 });
      dispatch('updateTableReady', { tableKey, ready: false });
      dispatch('updateTableSorting', { tableKey, sorting: { prop: null, order: null } });
    });
  },
};

const mutations = {
  [types.UPDATE_TABLES_STATUS](state, { tableKey, name, value }) {
    state.tablesStatus[tableKey] = { ...state.tablesStatus[tableKey], [name]: value };
  },
  [types.UPDATE_TABLE_SORTING](state, { tableKey, sorting }) {
    state.tablesStatus[tableKey] = { ...state.tablesStatus[tableKey], sorting };
  },
  [types.UPDATE_TABLE_CURRENT_PAGE](state, { tableKey, page }) {
    state.tablesStatus[tableKey] = { ...state.tablesStatus[tableKey], page };
  },
  [types.UPDATE_TABLE_PER_PAGE](state, { tableKey, perPage }) {
    state.tablesStatus[tableKey] = { ...state.tablesStatus[tableKey], perPage };
  },
  [types.UPDATE_TABLE_COUNT](state, { tableKey, count }) {
    state.tablesStatus[tableKey] = { ...state.tablesStatus[tableKey], count };
  },
  [types.UPDATE_TABLE_ROWS](state, { tableKey, rows }) {
    state.tablesStatus[tableKey] = { ...state.tablesStatus[tableKey], rows };
  },
  [types.UPDATE_TABLE_READY](state, { tableKey, ready }) {
    state.tablesStatus[tableKey] = { ...state.tablesStatus[tableKey], ready };
  },
};

export default {
  state: tablesState,
  getters,
  actions,
  mutations,
};
