
import moment from 'moment';

export default {
  install(Vue) {
    Vue.filter('sanitizer', (value) => {
      if (!value) return '-';
      return value;
    });
    Vue.filter('date', (value, format, sourceFormat) => {
      if (!value) return '-';
      return moment.utc(value, sourceFormat).format(format || 'DD/MM/YYYY');
    });
  },
};
