<template>
  <el-aside
    width="auto"
    class="sidebar"
  >
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
      class="sidebar-header"
    >
      <el-col
        v-if="!sidebarIsCollapsed"
        :span="13"
      >
        <el-image
          :src="require('@/assets/images/complife-group-logo.jpg')"
          fit="contain"
          class="sidebar-header__logo"
        />
      </el-col>
      <el-col
        v-if="!sidebarIsCollapsed"
        :span="7"
      >
        <el-tag
          v-if="deployment && deployment !== '' && deployment !== 'production'"
          class="sidebar-header__env"
          type="info"
        >
          {{ deployment }}
        </el-tag>
      </el-col>
      <el-col
        class="sidebar-header__collapse-container"
        :span="sidebarIsCollapsed?24:4"
      >
        <i
          :class="`sidebar-header__collapse-button ${sidebarIsCollapsedIcon}`"
          @click="collapseSidebar"
        />
      </el-col>
    </el-row>
    <div
      :class="[
        'menu__container',
        sidebarIsCollapsed?'menu__container--collapsed':'',
      ]"
    >
      <SidebarMenu
        :menu-items="menuItems"
        :sidebar-is-collapsed="sidebarIsCollapsed"
      />
      <div
        v-if="!sidebarIsCollapsed"
        class="version-text"
      >
        <span>Version {{ version }}</span>
      </div>
    </div>
  </el-aside>
</template>

<script>
import { mapGetters } from 'vuex';

import SidebarMenu from '@/components/SidebarMenu.vue';

export default {
  name: 'MainHeader',
  components: {
    SidebarMenu,
  },
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sidebarIsCollapsed: false,
      sidebarIsCollapsedIcon: 'el-icon-s-fold',
    };
  },
  computed: {
    ...mapGetters(['version', 'deployment']),
  },
  methods: {
    collapseSidebar() {
      this.sidebarIsCollapsed = !this.sidebarIsCollapsed;
      if (this.sidebarIsCollapsed) {
        this.sidebarIsCollapsedIcon = 'el-icon-s-unfold';
      } else {
        this.sidebarIsCollapsedIcon = 'el-icon-s-fold';
      }
    },
    runSync() {
      // TODO: replace with sync API and use menu default-active
      document.getElementById('menuItemSync').classList.add('is-active');
      setTimeout(() => document.getElementById('menuItemSync').classList.remove('is-active'), 3000);
    },
  },
};
</script>

<style lang="scss">
  .sidebar-header {
    height: $header-height;
    border-right: solid 1px #e6e6e6;
    border-bottom: solid 1px #e6e6e6;

    &__logo {
      box-sizing: border-box;
      width: auto;
      max-width: 100%;
      padding-left: 20px;
    }

    &__collapse-container {
      text-align: center;
    }

    &__collapse-button {
      cursor: pointer;
    }
  }
  .collapse-sidebar {
    cursor: pointer;
  }
  .sidebar {
    min-height: 100vh;
  }
  .menu {
    &__container {
      height: calc(100% - #{$header-height});
      overflow: hidden;
      background-color: $--menu-background-color;
      border-right: solid 1px #e6e6e6;
      display: flex;
      flex-direction: column;
    }
  }
  .menubar {
    &__sync {
      .menu-item {
        border-top: 1px solid #62798D;
        border-bottom: 0;
        height: 56px;
        font-size: 12px;
        line-height: 1.4;
        text-align: right;
        display: flex;
        justify-content: space-around;
        align-items: center;

        &.is-active {
          .menu-item__icon {
            animation: fa-spin 2s infinite linear;
          }
        }
      }
    }
  }
  .version-text {
    color: #ffffff;
    font-size: 0.87rem;
    height: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 20px 0 20px;
  }
</style>
