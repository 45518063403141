import store from '@/store';
import { ORDER_STATUSES_DESC, ORDER_LOCKED_DESC } from '@/config/constants';

const ordersColumns = {
  idUser: {
    key: 'id',
    label: 'orderConfirmId',
    type: 'text',
    sortable: 'custom',
    minWidth: 80,
    permissions: ['user'],
  },
  idAdmin: {
    key: 'id',
    label: 'orderId',
    type: 'text',
    sortable: 'custom',
    minWidth: 40,
    permissions: ['admin'],
  },
  createdAtUser: {
    key: 'createdAt',
    label: 'createdAt',
    type: 'date',
    sortable: 'custom',
    permissions: ['user'],
    columnCondition: 'draft',
  },
  sentAt: {
    key: 'sentAt',
    label: 'sentAt',
    type: 'date',
    sortable: 'custom',
    permissions: ['user'],
    columnCondition: 'sent',
  },
  titleAdmin: {
    key: 'title',
    label: 'order',
    type: 'text',
    sortable: 'custom',
    permissions: ['admin'],
  },
  customer: {
    key: 'customerName',
    label: 'customerName',
    type: 'text',
    sortable: true,
    permissions: ['admin'],
  },
  titleUser: {
    key: 'title',
    label: 'orderName',
    type: 'text',
    sortable: 'custom',
    permissions: ['user'],
  },
  customerOrderNumber: {
    key: 'customerOrderNumber',
    label: 'customerOrderNumber',
    type: 'text',
    sortable: false,
  },
  ondaOrderId: {
    key: 'ondaOrderId',
    label: 'ondaOrderId',
    type: 'text',
    sortable: false,
    permissions: ['admin'],
  },
  createdAt: {
    key: 'createdAt',
    label: 'createdAt',
    type: 'date',
    sortable: 'custom',
    permissions: ['admin'],
  },
  status: {
    key: 'status',
    label: 'status',
    sortable: 'custom',
    type: 'encoded',
    customClass: true,
    enum: 'orderStatuses',
    decode: value => ORDER_STATUSES_DESC[value],
    getClassModifier: value => ORDER_STATUSES_DESC[value],
  },
  locked: {
    key: 'locked',
    label: 'orderLocked',
    sortable: 'custom',
    type: 'encoded',
    customClass: true,
    permissions: ['admin'],
    enum: 'orderLocked',
    decode: value => ORDER_LOCKED_DESC[value],
    getClassModifier: value => ORDER_LOCKED_DESC[value],
  },
  analysisCount: {
    key: 'analysisCount',
    label: 'analysisCount',
    type: 'text',
    sortable: 'custom',
    permissions: ['user'],
  },
  actions: {
    key: 'actions',
    label: 'actions',
    type: 'actions',
    sortable: false,
    buttons: [
      {
        name: 'showSamples',
        icon: 'fas fa-eye',
        color: 'sand',
        permissions: ['admin'],
      },
      {
        name: 'editOrder',
        icon: 'fas fa-pencil',
        color: 'orange',
        permissions: ['ordersManage'],
        showCondition: row => !['rejected', 'canceled', 'completed'].includes(row.status) && !row.customerIsExpired,
      },
      {
        name: 'completeOrder',
        icon: 'fas fa-stamp',
        color: 'blue',
        permissions: ['user', 'ordersManage'],
        showCondition: row => row.status === 'draft',
      },
      {
        name: 'showOrderSamples',
        icon: 'fas fa-clipboard-list',
        color: 'orange',
        permissions: ['user', 'ordersView'],
        // showCondition: row => row.status === 'sent',
      },
      {
        name: 'deleteOrder',
        icon: 'fas fa-trash-alt',
        color: 'red',
        permissions: ['ordersManage'],
        showCondition: row => row.status === 'draft',
      },
      {
        name: 'exportRawData',
        icon: 'fas fa-pencil-ruler',
        color: 'blue',
        permissions: ['user', 'ordersManage'],
        enableCondition: () => store.getters.canViewRawData,
        showCondition: row => row.hasRawData,
      },
    ],
  },
};

export default ordersColumns;
