import { ANALYSES_COMMODITY_CATEGORIES } from '@/config/constants';

const analysesFilters = [
  {
    name: 'description',
    label: 'description',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'code',
    label: 'code',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'commodityCategory',
    label: 'commodityCategory',
    type: 'select',
    whereOperator: '=',
    options: [
      { label: 'enums.commodityCategory.chemical', value: ANALYSES_COMMODITY_CATEGORIES.CHEMICAL },
      { label: 'enums.commodityCategory.microbiological', value: ANALYSES_COMMODITY_CATEGORIES.MICROBIOLOGICAL },
    ],
  },
  {
    name: 'class',
    label: 'class',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'enabled',
    label: 'activeInactive',
    type: 'select',
    whereOperator: '=',
    options: [
      { label: 'enums.enabledStatus.enabled', value: true },
      { label: 'enums.enabledStatus.disabled', value: false },
    ],
  },
];

export default analysesFilters;
