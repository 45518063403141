import * as types from './mutation-types';

export default {
  [types.SET_READY](state) {
    state.ready = true;
  },
  [types.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
};
