import store from '../store';

export default {
  userCanUse(resourcePermissions = [], checkAllPermissions = false) {
    // Returns true if no resourcePermissions are specified
    if (!resourcePermissions.length) return true;
    if (checkAllPermissions) {
      return resourcePermissions.every(p => store.getters.userPermissions.includes(p));
    }
    return !!(resourcePermissions.filter(
      value => (store.getters.userPermissions || []).includes(value),
    ).length);
  },
};
