<template>
  <el-container class="prelogin-container">
    <MainBackground />
    <el-image
      :src="require('@/assets/images/complife-group-logo-xl.jpg')"
      fit="contain"
      class="logo-img"
    />
    <el-main class="prelogin-main">
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
import MainBackground from '@/components/MainBackground.vue';

export default {
  name: 'PreLogin',
  components: {
    MainBackground,
  },
};
</script>

<style lang="scss">
.prelogin-container {
  position: relative;
  height: 100vh;

  .logo-img {
    position: absolute;
    top: $--main-padding;
    left: $--main-padding;
  }

  .prelogin-main {
    padding: 0;
  }
}
</style>
