import { ordersApi } from '@/api';
import { SAMPLE_STATUSES_DESC } from '@/config/constants';

const samplesFilters = [
  // {
  //   name: 'id',
  //   label: 'sampleId',
  //   type: 'text',
  //   whereOperator: '=',
  // },
  {
    name: 'orderId',
    label: 'orderName',
    type: 'autocomplete',
    autocompleteSearch: async (queryString, cb) => {
      const ordersResponse = await ordersApi.findOrdersByTitle(queryString);
      const filteredResults = ordersResponse.data.map(item => (
        {
          label: item.title,
          value: item.id,
        }
      ));
      // call callback function to return suggestions
      cb(filteredResults);
    },
    whereOperator: '=',
  },
  {
    name: 'id',
    label: 'sampleId',
    type: 'number',
    whereOperator: '=',
  },
  {
    name: 'productName',
    label: 'productName',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'customerProductName',
    label: 'customerProductName',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'lot',
    label: 'lot',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'status',
    label: 'status',
    type: 'select',
    whereOperator: '=',
    options: Object.keys(SAMPLE_STATUSES_DESC)
      .map(key => ({ label: `enums.sampleStatuses.${SAMPLE_STATUSES_DESC[key]}`, value: key })),
  },
  {
    name: 'sampleReportCodes',
    label: 'sampleReportCodes',
    type: 'text',
    whereOperator: 'regexp',
  },
  // {
  //   name: 'date',
  //   label: 'date',
  //   type: 'dateRange',
  // },
];

export default samplesFilters;
