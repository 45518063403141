import moment from 'moment';

const getFilterValue = (whereOperator, rawValue) => {
  switch (whereOperator) {
    case 'regexp':
      return `/${rawValue}/i`;
    default:
      return rawValue;
  }
};

const getFilter = (filterConfig, rawValue) => {
  switch (filterConfig.type) {
    case 'dateDay':
      return {
        between:
        [
          getFilterValue(filterConfig.whereOperator, moment.utc(rawValue).startOf('day').toDate()),
          getFilterValue(filterConfig.whereOperator, moment.utc(rawValue).endOf('day').toDate()),
        ],
      };
    case 'dateRange':
      return {
        between:
        [
          getFilterValue(filterConfig.whereOperator, moment.utc(rawValue[0]).startOf('day').toDate()),
          getFilterValue(filterConfig.whereOperator, moment.utc(rawValue[1]).endOf('day').toDate()),
        ],
      };
    default:
      return filterConfig.whereOperator === '=' ? rawValue : {
        [filterConfig.whereOperator]: getFilterValue(filterConfig.whereOperator, rawValue),
      };
  }
};

const buildOrConditionFiltersValues = (
  filterKey, filterValues, filterConfig,
) => filterValues.reduce((orConditionsAccumulator, item) => {
  // Send only not blank filters
  if (!('key' in item) || item.key === '' || item.key === null) {
    return [...orConditionsAccumulator];
  }
  return [
    ...orConditionsAccumulator,
    { [filterKey]: getFilter(filterConfig, item.key) },
  ];
},
[]);

const buildAndConditionFilters = (
  filters, filtersConfig,
) => Object.keys(filters).reduce((andAccumulator, key) => {
  const filterConfig = filtersConfig.find(item => item.name === key);
  const orCondition = buildOrConditionFiltersValues(key, filters[key], filterConfig);

  if (orCondition.length === 0) {
    return [...andAccumulator];
  }
  return [
    ...andAccumulator,
    { or: orCondition },
  ];
},
[]);

export default {
  // creating where object for backend
  whereFilters: (filters, filtersConfig) => {
    const andCondition = buildAndConditionFilters(filters, filtersConfig);
    return andCondition.length > 0 ? { and: andCondition } : {};
  },
};
