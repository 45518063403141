export default {
  global: {
    continue: 'Continue',
    cancel: 'Cancel',
    back: 'Back',
    warning: 'Warning!',
    notPdfFile: 'Upload a valid pdf file!',
    browse: 'Browse',
    notCsvFile: 'Upload a valid cvs file',
    fileExceeded: 'Upload a file of size <{0} Mb',
    noMultipleFilesUpload: 'You need to remove the previous file before you can upload a new one!',
    uploadFilesLimitExceeded: 'Upload files limit exceeded',
    confirmUploadCancel: 'Are you sure you want to remove the file?',
    backToList: 'Back to List',
    edit: 'Edit',
    select: 'Select',
    add: 'Add',
    apply: 'Apply',
    lang: 'Language',
    italian: 'Italian',
    english: 'English',
    spanish: 'Spanish',
    attachment: 'Attachment',
    removeSwitchPdf: 'Change/Remove PDF',
    uploadPdf: 'PDF Upload',
    downloadPdf: 'Download PDF',
    help: 'Help',
    connectionError: 'Server connection error!',
    requiredFields: 'Field tagged with * are required',
  },
  permissions: {
    title: 'Permissions',
    admin: 'Super Admin',
    news: 'News',
    newsView: 'View',
    newsManage: 'Manage',
    offers: 'Offers',
    offersView: 'View',
    offersManage: 'Manage',
    orders: 'Orders',
    ordersView: 'View',
    ordersManage: 'Manage',
    samplesStatus: 'Sample statuses',
    samplesStatusView: 'View',
    samplesStatusManage: 'Manage',
    billing: 'Billing',
    billingView: 'View',
    billingManage: 'Manage',
    users: 'Users',
    usersView: 'View',
    usersManage: 'Manage',
    customers: 'Companies',
    customersView: 'View',
    customersManage: 'Manage',
  },
  paneContent: {
    testLabel: 'test',
  },
  common: {
    noPermissonRoute: 'You do not have permission to access this page!',
  },
  table: {
    massiveActions: {
      title: 'Apply state to everyone',
      submit: 'Apply',
    },
  },
  header: {
    welcome: 'Welcome',
    titles: {
      offersIndex: 'Offers list',
      usersIndex: 'Users list',
      analysesIndex: 'Test list',
      analysisCreate: 'Create Test / Class',
      analysisEdit: 'Modify Test',
      newOffer: 'Create offer',
      editOffer: 'Modify offer',
      showOffer: 'Offer',
      customersIndex: 'Customer',
      customersEdit: 'Modify customer',
      ordersIndex: 'Orders list',
      editOrder: 'Modify Order',
      editOrderSample: 'Modify Sample',
    },
  },
  tooltips: {
    offersIndex: 'List of current offers',
    requestOffer: 'In this screen you can request the emission of an expired offer or for a new type of test',
    ordersIndex: 'In this screen you can perform and monitor your orders',
    samplesIndex: 'In this screen you can monitor your samples and download results',
    analysesIndex: 'In this screen you can monitor the status of your sample analysis',
    orderName: 'Assign a name to your order',
    customerOrderNumber: 'Enter your purchase order number, so that it appears correctly in the invoice',
    massiveXlsOrder: 'Fill in the Excel file by following the instructions in it and use it to load multiple samples simultaneously',
    remainingAnalyses: 'Remaining test number. Valid only for Subscriptions and grouped',
    newSample: {
      labSampleCode: 'Article code of the sample',
      productName: 'Name of sample (is required)',
      customerSampleCode: 'Sample code of the customer',
      lot: 'Sample batch',
      lotDate: 'Date of production of the batch',
      sector: 'Product sector of the sample (is required - select from list)',
      lotNumber: 'Number of packages of the sample (are required)',
      note: 'Customer Notes (present in the Test Report)',
      finalCustomer: 'Final customer who commissioned the sample',
      finalCustomerRef: 'Ref. of the final customer who commissioned the sample',
      description: 'Description of sample (select from list)',
      comments: 'Additional notes (not present in the Test Report)',
      costCenter: 'Customer cost center',
      pdfUpload: 'Upload files containing all the documents you want to communicate to Complife for a correct execution of the test (e.g. specifications, datasheet), or that are required for the execution of the test (example INCI)',
    },
  },
  menuItems: {
    usersIndex: 'Users',
    analysesIndex: 'Tests',
    offersIndex: 'Offers',
    requestOffer: 'Request offer',
    customersIndex: 'Customers',
    ordersIndex: 'Orders',
    samplesIndex: 'Samples',
    newsAdminIndex: 'News',
    newsUserIndex: 'News',
  },
  loginView: {
    usernameInput: 'User',
    passwordInput: 'Password',
    passwordForgotLabel: 'Forgot password?',
    accessButton: 'Log In',
    title: 'Your',
    titleBlue: 'laboratory',
  },
  passwordResetView: {
    title: 'Enter your new password',
    passwordInput: 'Password',
    confirmPasswordInput: 'Confirm password',
    submitButton: 'Submit',
    passwordChangedMessage: 'Password changed correctly',
  },
  passwordRecoverView: {
    title: 'Recover your password',
    emailInput: 'Email',
    submitButton: 'Submit',
    passwordRecoveredMessage: 'Further instructions have been sent to your e-mail address',
  },
  logout: {
    label: 'Logout',
    successMessage: 'Logout successful',
  },
  users: {
    create: {
      givenName: 'Name',
      familyName: 'Surname',
      email: 'Email',
      emailConfirmation: 'Confirm Email',
      password: 'Password',
      passwordConfirmation: 'Confirm Password',
      phone: 'Phone',
      createUser: 'Create User',
      abortCreate: 'Cancel',
      success: 'User successfully created',
      customer: 'Business name',
      role: 'Role',
      function: 'Function',
      functions: {
        qc: 'Quality Control',
        qa: 'Quality Assurance',
        rd: 'Research and Development',
        production: 'Production',
        administration: 'Administration',
        logistics: 'Logistics',
        purOff: 'Purchase office',
      },
    },
    update: {
      success: 'User successfully updated',
      updateUser: 'Update User',
      invitationSent: 'Verification email sent',
    },
  },
  customers: {
    create: {
      createCustomer: 'Create company',
      name: 'Name',
      address: 'Address',
      vat: 'VAT Number',
      sdi: 'SDI',
      city: 'City',
      district: 'District',
      phoneNumber: 'Phone',
      mobile: 'Mobile',
      isEnabled: 'Enabled',
      internalReference: 'Onda Id',
      zip: 'ZIP',
      certifiedEmail: 'Email',
      fiscalCode: 'Fiscal code',
      abortCreate: 'Cancel',
      success: 'Company successfully created',
      agent: 'Agent',
    },
    update: {
      updateCustomer: 'Modify company',
      success: 'Company successfully updated',
    },
  },
  orders: {
    orderSamples: 'Samples',
    saveForLater: 'Save for later',
    print: 'Print',
    send: 'Send',
    create: {
      createOrder: 'Create Order',
      title: 'Order name',
      customer: 'Company',
      customerId: 'Company',
      customerOrderNumber: 'Order number',
      abortCreate: 'Cancel',
      success: 'Order successfully created',
      sent: 'Order with code {orderId} successfully sent',
      saved: 'Order saved',
      confirmSend: 'Are you sure you want to continue?',
      cancelled: 'Operation cancelled',
      separateInvoice: 'Do you want a separate invoice?',
      separateInvoiceYes: 'Yes',
      separateInvoiceNo: 'No',
    },
    update: {
      updateOrder: 'Update',
      success: 'Order successfully updated',
    },
    samples: {
      uploadReport: 'Upload report',
      uploadReports: 'Send',
      abortUpload: 'Abort',
      importSuccess: 'Imported successfully',
      importError: 'Import error, check the validity of the CSV file',
    },
    delete: {
      confirm: 'Do you want to remove the order?',
      success: 'Order removed',
      cancelled: 'Action aborted',
    },
  },
  accreditedTestModal: {
    title: 'Attention!',
    warning: 'Attention, accredited test',
    description: 'To add this analysis you must first fill in the field "Lot" for',
    samples: 'no sample | another {n} sample | other {n} samples',
    next: 'Edit and go to the next sample',
    confirm: 'Finish editing and add test',
    abort: 'Cancel',
    lot: 'Batch per sample "{0}":',
  },
  dynamicAnalysesModal: {
    confirm: 'Confirm and add analysis',
    abort: 'Abort',
    wrongNumber: 'Choose {n} analysis to continue!',
  },
  analyses: {
    index: {
      createAnalysis: 'Create Test / Class',
    },
    create: {
      formHeader: 'Create Test / Class',
      class: 'Class',
      code: 'Article code',
      commodityCode: 'Department',
      statisticCode: 'Statistical class',
      natureCode: 'Nature code',
      description: 'Description',
      extraDescription: 'Extra description',
      commodityCategory: 'Department code description',
      statisticCategory: 'Description statistic category',
      nature: 'Description nature code',
      brandCode: 'Technique code',
      familyCode: 'Family code',
      departmentCode: 'Department code',
      methodCode: 'Method code',
      brand: 'Technique / instrument',
      family: 'Family',
      department: 'Department',
      method: 'Method description',
      measure: 'Measure description',
      measureUnitCode: 'Unity of measure',
      accreditedTest: 'Accredited test',
      expirationDate: 'Article Expiration date ',
      measureCode: 'Measure code',
      dynamicClass: 'Dynamic class',
      fixedClass: 'Fixed class',
      singleAnalysis: 'Analysis',
      submitButton: 'Submit',
      classType: 'Class Type',
      maxAnalysesNumber: 'Maximum analyses number',
      searchAnalyses: 'Find test',
      analysisTypeFilter: 'Filter by type',
      removeAnalysis: 'Remove',
      success: 'Test successfully created',
      abortCreate: 'Abort',
    },
    update: {
      addToClass1: 'Add to a class',
      addToClass2: 'Add to class',
      formHeader: 'Modify test',
      success: 'Test successfully modified',
    },
  },
  filters: {
    user: 'User',
    customer: 'Customer',
    enabled: 'Enabled',
    disabled: 'Disabled',
    name: 'Name',
    companyName: 'Company name',
    date: 'Date',
    address: 'Address',
    city: 'City',
    email: 'Email',
    emailAddress: 'Email address',
    sector: 'Sector',
    phone: 'Phone',
    zipCode: 'ZIP code',
    vat: 'VAT number',
    activeInactive: 'Active / Inactive',
    active: 'Active',
    inactive: 'Inactive',
    fromDate: 'From date',
    toDate: 'To date',
    clearFilter: 'Clear',
    givenName: 'Name',
    familyName: 'Surname',
    analysisCode: 'Test code',
    customDescription: 'Description',
    quantity: 'Quantity',
    applyFilter: 'Apply filter',
    actions: 'Actions',
    docNumber: 'Document number',
    issuedAt: 'Document date',
    comment: 'Comment',
    isEnabled: 'Enabled',
    isVerified: 'Verified',
    customerName: 'Business name',
    expirationDate: 'Expiration date',
    type: 'Type',
    description: 'Description',
    additionalDescription: 'Extra description',
    method: 'Method',
    accreditedTest: 'Accredited test',
    remaining: 'Remaining',
    class: 'Class',
    title: 'Order name',
    startDate: 'From date',
    endDate: 'To date',
    from: 'From',
    createdAt: 'Creation date',
    sentAt: 'Sent at',
    sampleCount: 'Samples number',
    analysisCount: 'Analysis count',
    status: 'Status',
    draft: 'Draft',
    sent: 'Sent',
    offer: 'Offer',
    reportsList: 'Reports list',
    commodityCategory: 'Product category',
    code: 'Article code',
    labSampleCode: 'Sample code',
    productName: 'Product name',
    lot: 'Lot',
    order: 'Order',
    orderName: 'Order name',
    countryCode: 'Language code',
    role: 'Role',
    customerCode: 'Customer code',
    methodCode: 'Method code',
    revisionNumber: 'Revision number',
    revisionDate: 'Revision date',
    price: 'Price',
    orderNumber: 'Order number',
    ondaOrderId: 'ONDA Order ID',
    publishedAt: 'Published At',
    newsTitle: 'News title',
    childrenCount: 'Analysis count',
    orderId: 'Order ID',
    sampleId: 'Sample ID',
    rejectReason: 'Reject reason',
    offerEnabled: 'Active',
    ordersSent: 'Sent',
    orderConfirmId: 'Order confirmation number',
    testType: 'Test type',
    offerNumber: 'Offer number',
    customerOrderNumber: 'Customer order number',
    function: 'Function',
    customerProductName: 'Customer product name',
    sampleReportCodes: 'Sample report codes',
  },
  tableButtons: {
    downloadOffer: 'Download offer',
    seeDetail: 'See detail',
    change: 'Modify',
    reloadExcel: 'Reload excel',
    disableSelected: 'Disable selected',
    disable: 'Disable',
    enable: 'Enable',
    disableUser: 'Disable user',
    enableUser: 'Enable user',
    userOrders: 'Orders',
    editCustomer: 'Edit customer',
    editUser: 'Edit user',
    disableCustomer: 'Disable customer',
    enableCustomer: 'Enable customer',
    editArticle: 'Edit article',
    disableAnalysis: 'Disable test',
    enableAnalysis: 'Enable test',
    addAnalysisToClass: 'Add analysis to class',
    editOrder: 'Edit order',
    completeOrder: 'Complete order',
    editSample: 'Edit sample',
    deleteSample: 'Delete sample',
    cloneSample: 'Duplicate sample',
    showSamples: 'Show samples',
    uploadPdf: 'Upload PDF',
    changePdf: 'Change pdf',
    renameSample: 'Rename',
    downloadSampleReport: 'Download sample report',
    addAnalyses: 'Add analyses',
    showAnalyses: 'Show analyses',
    showOrderSamples: 'Show order samples',
    selectOnlyThisSampleOn: 'Display only associated tests',
    selectOnlyThisSampleOff: 'Display all tests',
    showUsers: 'Display all users',
    exportExcel: 'Export Excel',
    editNews: 'Edit news',
    deleteNews: 'Delete news',
    downloadExcel: 'Download Excel',
    uploadSampleReport: 'Upload PDF',
    deleteOrder: 'Delete order',
    sendVerificationEmail: 'Send verification email',
    exportRawData: 'Download Raw Data',
    enableRawData: 'Enable Raw Data',
    disableRawData: 'Disable Raw Data',
  },
  enums: {
    commons: {
      yes: 'Yes',
      no: 'No',
    },
    offerType: {
      list: 'List',
      quantity: 'Quantity',
      brackets: 'Brackets',
      null: 'None',
    },
    enabledStatus: {
      enabled: 'Enabled',
      disabled: 'Disabled',
    },
    commodityCategory: {
      microbiological: 'Microbiological',
      chemical: 'Chemical',
      vitro: 'Vitro',
    },
    sampleStatuses: {
      created: 'Created',
      pending: 'Pending',
      accepted: 'Accepted',
      inprogress: 'In progress',
      draft: 'Draft',
      concluded: 'Closed',
      canceled: 'Cancelled',
      rejected: 'Rejected',
    },
    newsStatuses: {
      published: 'Published',
      unpublished: 'Draft',
    },
    orderStatuses: {
      draft: 'Draft',
      sent: 'Sent',
      accepted: 'Accepted',
    },
    analysisStatuses: {
      none: 'Created',
      accepted: 'Accepted',
      inprogress: 'In progress',
      completed: 'Completed',
      deleted: 'Deleted',
      suspended: 'Suspended',
      pending: 'Pending',
    },
    functions: {
      qc: 'Quality Control',
      qa: 'Quality Assurance',
      rd: 'Research and Development',
      production: 'Production',
      administration: 'Administration',
      logistics: 'Logistics',
      purOff: 'Purchase office',
      undefined: '-',
    },
  },
  samples: {
    showReportsList: 'Show all reports',
    delete: {
      confirm: 'Are you sure you want to delete the sample?',
      success: 'Sample cancelled!',
      cancelled: 'Deletion cancelled!',
    },
    update: {
      success: 'Sample successfully updated',
      updateSample: 'Update sample',
    },
    clone: {
      confirm: 'Are you sure you want to duplicate the sample?',
      success: 'Sample duplicated',
    },
    create: {
      import: 'XLSX massive upload',
      downloadExample: 'Download XLSX',
      success: 'Sample successfully created',
      labSampleCode: 'Product code',
      productName: 'Product name',
      customerProductName: 'Customer sample code',
      lot: 'Lot',
      date: 'Lot date',
      sector: 'Sector',
      packagesNumber: 'Samples number',
      notes: 'Customer notes',
      finalCustomer: 'Final customer',
      sampleDescription: 'Sample description',
      comments: 'Textual Instructions',
      costCenter: 'Customer cost center',
      abortCreate: 'Cancel',
      createSample: 'Create sample',
      actions: 'Actions',
      customerHeaderData: 'Do you want to print the customer data in the header?',
      customerHeaderDataYes: 'Yes',
      customerHeaderDataNo: 'No',
      customerAddress: 'Customer Address',
      address: {
        addNew: 'Add new',
        name: 'Company name',
        address: 'Address',
        city: 'City',
        zip: 'ZIP Code',
        province: 'Province',
        phoneNumber: 'Phone number',
      },
      fileSize: 'PDF files with a size less than 10 MB',
    },
    sectors: {
      foodSupplement: 'Food supplement',
      other: 'Other',
      chemical: 'Chemical',
      cosmetic: 'Cosmetic',
      detergents: 'Detergent',
      medicalDevice: 'Medical Device',
      packaging: 'Packaging material',
      biocide: 'Biocide',
      pharmaceutical: 'Pharmaceutical',
    },
    sampleDescriptions: {
      anhydrousFluid: 'Anhydrous fluid',
      anhydrousPaste: 'Anhydrous paste',
      sanitaryTowelsDiapers: 'Sanitary Towels / Diapers',
      biphasic: 'Bifasic',
      capsule: 'Capsule',
      tablet: 'Tablet',
      emulsion: 'Emulsion',
      liquidGel: 'Liquid Gel',
      liquid: 'Liquid',
      oil: 'Oil',
      packaging: 'Packaging',
      patch: 'Patch',
      pressedPowder: 'Pressed Powder',
      loosePowder: 'Loose Powder',
      wipes: 'Wipes',
      validation: 'Validation',
    },
  },
  offer: {
    index: {
      createOffer: 'Create offer',
    },
    request: {
      offer: 'Past offers',
      message: 'Message',
      privacyConsent: ' ',
      privacyConsentLabel: 'Personal Data Processing',
      privacyConsentText: 'I have read and accept the information relating to the processing of personal data pursuant to EU Regulation 2016/679 articles 13 and 14',
      marketingConsentText1: 'You authorize us to contact you and send information by email about our services / products / events and promotions that will interest you.',
      marketingConsentText2: 'Authorization to process data for this purpose is not necessary, but you would risk missing out on something that might interest you.',
      success: 'Request sent successfully',
    },
    create: {
      formHeader: 'Create offer',
      request: 'Do you want to request an offer?',
      requestSubtitle: 'Fill in the form below',
    },
    update: {
      formHeader: 'Edit offer',
      success: 'Offer updated',
    },
    help: {
      filter: {
        admin: {
          docNumber: 'Document number',
        },
      },
      field: {
        admin: {
          docNumber: 'Document number',
        },
      },
    },
    ondaOfferId: 'ID  Onda Document',
    customer: 'Customer',
    docNumber: 'Document number',
    issuedAt: 'Document date',
    yourReference: 'Your reference',
    ourReference: 'Our reference',
    comment: 'Comment',
    revisionNumber: 'Offer revision number',
    revisionDate: 'Revision date',
    pdfFileId: 'Upload pdf',
    downloadPdf: 'Download pdf',
    status: 'Status',
    editSuccess: 'Offer successfully modified!',
    createError: 'Error creating offer!',
    uploadCsvFile: 'Upload file excel',
    expirationDate: 'Expiration Date',
  },
  news: {
    index: {
      noRecords: 'No news present',
    },
    create: {
      createNews: 'Create news',
      formHeader: 'Create news',
      submitButton: 'Save and publish news',
      draftSubmitButton: 'Save draft',
      publishedAt: 'Published at',
      title: 'News title',
      image: 'Upload news image',
      attachments: 'Upload Attachments',
      abstract: 'Abstract text',
      text: 'Text',
      success: 'News created',
      imagePlaceholder: 'Upload image 1200 x 400',
      isPublished: 'Published',
    },
    update: {
      success: 'News modified with success',
      bulkSuccess: 'News modified with success',
      formHeader: 'Edit news',
      draftSubmitButton: 'Draft',
      submitButton: 'Edit news',
    },
    delete: {
      confirm: 'Are you sure you want to delete the news?',
      success: 'News deleted!',
      cancelled: 'Undo elimination',
    },
    detail: {
      title: 'News detail',
      attachments: 'Attached documents',
    },
  },
};
