import * as types from '../mutation-types';

const initialState = {
  filtersStatus: {
    customers: {},
    users: {},
    offers: {},
    offerItems: {},
    analyses: {},
    orders: {},
    samples: {},
    sampleAnalyses: {},
    orderSamples: {},
    orderSampleAnalyses: {},
    news: {},
    invoices: {},
  },
};

const filtersState = { ...initialState };


const getters = {
  filtersStatus: state => state.filtersStatus,
};

const actions = {
  applyFilters({ commit }, { filterKey, filterData }) {
    commit(types.APPLY_FILTERS, { filterKey, filterData });
  },
  resetFilters({ commit }, filterKey) {
    commit(types.RESET_FILTERS, filterKey);
  },
  resetAllFilters({ commit }) {
    Object.keys(filtersState.filtersStatus).forEach((key) => {
      commit(types.RESET_FILTERS, key);
    });
  },
};

const mutations = {
  [types.APPLY_FILTERS](state, { filterKey, filterData }) {
    state.filtersStatus[filterKey] = filterData;
  },
  [types.RESET_FILTERS](state, filterKey) {
    state.filtersStatus[filterKey] = {};
  },
};

export default {
  state: filtersState,
  getters,
  actions,
  mutations,
};
