import tableHelper from '@/services/tableHelper';
import http from '../services/http';
import { invoicesFilters } from '../config';

const invoices = {
  index(tableFilters, filterStatus) {
    const filters = tableHelper.whereFilters(filterStatus, invoicesFilters);
    return http.request({
      method: 'get',
      url: '/invoices',
      params: { filter: { ...tableFilters, where: filters } },
    });
  },
  count(filterStatus) {
    const where = tableHelper.whereFilters(filterStatus, invoicesFilters);
    return http.request({
      method: 'get',
      url: '/invoices/count',
      params: { where },
    });
  },
  getInvoicePdf(id) {
    try {
      return http.request({
        method: 'get',
        url: `/invoices/${id}/invoice`,
        responseType: 'blob',
      });
    } catch (e) {
      throw e;
    }
  },
  getAttachmentPdf(id) {
    try {
      return http.request({
        method: 'get',
        url: `/invoices/${id}/attachment`,
        responseType: 'blob',
      });
    } catch (e) {
      throw e;
    }
  },
};

export default invoices;
