const usersFilters = [
  {
    name: 'givenName',
    label: 'givenName',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'familyName',
    label: 'familyName',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'customerName',
    label: 'customerName',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'customerCategoryDescription',
    label: 'sector',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'isEnabled',
    label: 'activeInactive',
    type: 'select',
    whereOperator: '=',
    options: [
      { label: 'filters.active', value: 1 },
      { label: 'filters.inactive', value: 0 },
    ],
  },
];

export default usersFilters;
