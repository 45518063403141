import * as types from '../mutation-types';

const initialState = {
  version: null,
  deployment: null,
};

const versionState = { ...initialState };

const getters = {
  version: state => state.version,
  deployment: state => state.deployment,
};

const actions = {
  setVersion({ commit }, version) {
    commit(types.SET_VERSION, version);
  },
  setDeployment({ commit }, deployment) {
    commit(types.SET_DEPLOYMENT, deployment);
  },
};

const mutations = {
  [types.SET_VERSION](state, version) {
    state.version = version;
  },
  [types.SET_DEPLOYMENT](state, deployment) {
    state.deployment = deployment;
  },
};

export default {
  state: versionState,
  getters,
  actions,
  mutations,
};
