import { SAMPLE_STATUSES_DESC } from '@/config/constants';

const ordersColumns = {
  labSampleCode: {
    key: 'labSampleCode',
    label: 'labSampleCode',
    type: 'text',
    sortable: 'custom',
    permissions: ['admin'],
  },
  status: {
    key: 'status',
    label: 'status',
    type: 'encoded',
    sortable: 'custom',
    customClass: true,
    permissions: ['admin'],
    enum: 'sampleStatuses',
    decode: value => SAMPLE_STATUSES_DESC[value],
    getClassModifier: value => SAMPLE_STATUSES_DESC[value],
    minWidth: 20,
  },
  actions: {
    key: 'actions',
    label: 'actions',
    type: 'actions',
    sortable: false,
    minWidth: 50,
    buttons: [
      {
        name: 'showAnalyses',
        icon: 'fas fa-eye',
        color: 'sand',
        permissions: ['admin', 'ordersView'],
      },
      {
        name: 'addAnalyses',
        icon: 'fas fa-pencil',
        color: 'orange',
        permissions: ['admin', 'ordersManage'],
      },
    ],
  },
};

export default ordersColumns;
