const offersColumns = {
  docNumber: {
    key: 'docNumber',
    label: 'docNumber',
    type: 'function',
    sortable: 'custom',
    get: (value, row) => {
      const revisionNumber = row.revisionNumber ? String(`0${row.revisionNumber}`).slice(-2) : false;
      return revisionNumber ? `${value}-${revisionNumber}` : value;
    },
  },
  issuedAt: {
    key: 'documentDate',
    label: 'issuedAt',
    type: 'date',
    sortable: 'custom',
  },
  revisionDate: {
    key: 'revisionDateLocal',
    label: 'revisionDate',
    type: 'date',
    sortable: 'custom',
  },
  customerName: {
    key: 'customerName',
    label: 'customerName',
    type: 'text',
    sortable: 'custom',
    permissions: ['admin'],
  },
  expirationDate: {
    key: 'expirationDate',
    label: 'expirationDate',
    type: 'date',
    sortable: 'custom',
  },
  price: {
    key: 'price',
    label: 'price',
    type: 'text',
    sortable: 'custom',
  },
  type: {
    key: 'type',
    label: 'type',
    type: 'enum',
    enum: 'offerType',
    sortable: 'custom',
  },
  enabled: {
    label: 'offerEnabled',
    type: 'boolean',
    minWidth: 40,
    sortable: false,
    align: 'center',
  },
  actions: {
    key: 'actions',
    label: 'actions',
    type: 'actions',
    sortable: false,
    buttons: [
      {
        name: 'downloadOffer',
        icon: 'fas fa-file-pdf', // FontAwesome icon class
        color: 'red', // color defined in common-variables.scss
        permissions: ['offersView'],
        showCondition: row => row.pdfFileId !== null,
      },
      {
        name: 'seeDetail',
        icon: 'fas fa-eye',
        color: 'sand',
      },
      {
        name: 'disable',
        icon: 'fas fa-minus-circle',
        color: 'red-light',
        permissions: ['admin', 'offersManage'],
        showCondition: row => row.enabled === true,
      },
      {
        name: 'enable',
        icon: 'fas fa-plus-circle',
        color: 'green',
        permissions: ['admin', 'offersManage'],
        showCondition: row => row.enabled === false && row.isLastRevision,
      },
    ],
  },
};

export default offersColumns;
