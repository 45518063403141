import i18n from '@/i18n';
import axios from 'axios';
import storage from 'store';
import store from '../store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// const TOKEN_EXPIRED_MESSAGE = 'Error verifying token : jwt expired';
const TOKEN_EXPIRED_CODE = 'JWT_EXPIRED';

axios.interceptors.request.use((config) => {
  const token = config.url === '/auth/refresh' ? storage.get('refreshToken') : storage.get('authToken');
  store.dispatch('setIsLoading', true);
  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        'Accept-Language': i18n.locale,
      },
    };
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      'Accept-Language': i18n.locale,
    },
  };
}, (error) => {
  throw error;
});

// Deserialize errors for requests with responseType='blob', so that
// error.response.data is defined and refresh token interceptor can always work
// https://github.com/axios/axios/issues/815#issuecomment-453963910
axios.interceptors.response.use(
  response => response,
  (error) => {
    const { response } = error;
    if (
      error.request && error.request.responseType === 'blob'
          && response.data instanceof Blob
          && response.data.type
          && response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };
        reader.onerror = () => {
          reject(error);
        };
        reader.readAsText(error.response.data);
      });
    }
    return Promise.reject(error);
  },
);

axios.interceptors.response.use((response) => {
  store.dispatch('setIsLoading', false);
  const { token: authToken, refreshToken } = response.data || {};
  if (authToken && refreshToken) {
    storage.set('authToken', authToken);
    storage.set('refreshToken', refreshToken);
  }
  return response;
},
async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401
        && error.response.data.error.code
        && error.response.data.error.code === TOKEN_EXPIRED_CODE && originalRequest.url !== '/auth/refresh') {
    const refreshResponse = await axios.post('/auth/refresh');
    if (refreshResponse.status === 200) return axios(originalRequest);
  } else if (error.response.status === 401 && originalRequest.url === '/auth/refresh') {
    store.dispatch('setLoggedUser', null);
  }
  throw error;
});

export default axios;
