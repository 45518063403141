import http from '../services/http';

const uploads = {
  uploadFile(stream) {
    try {
      return http.request({
        method: 'post',
        url: '/uploads',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        data: stream,
      });
    } catch (e) {
      throw e;
    }
  },
  retrieveFile(fileId) {
    return http.request({
      method: 'get',
      url: `/uploads/${fileId}`,
      responseType: 'blob',
    });
  },
};

export default uploads;
