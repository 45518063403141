const customersColumns = {
  name: {
    key: 'name',
    label: 'customerName',
    type: 'text',
    sortable: 'custom',
  },
  countryCode: {
    key: 'countryCode',
    label: 'countryCode',
    type: 'text',
    sortable: 'custom',
  },
  vat: {
    key: 'vat',
    label: 'vat',
    type: 'text',
    sortable: false,
  },
  city: {
    key: 'city',
    label: 'city',
    type: 'text',
    sortable: false,
  },
  // address: {
  //   key: 'address',
  //   label: 'address',
  //   type: 'text',
  //   sortable: false,
  // },
  // zip: {},
  isEnabled: {
    key: 'isEnabled',
    label: 'isEnabled',
    type: 'boolean',
    minWidth: 40,
    sortable: false,
  },
  canViewRawData: {
    key: 'canViewRawData',
    label: 'canViewRawData',
    type: 'boolean',
    minWidth: 40,
    sortable: false,
  },
  actions: {
    label: 'actions',
    type: 'actions',
    sortable: false,
    minWidth: 100,
    buttons: [
      {
        name: 'enableRawData',
        icon: 'fas fa-eye',
        color: 'blue-light',
        permissions: ['admin', 'customersManage'],
        showCondition: row => !row.canViewRawData,
      },
      {
        name: 'disableRawData',
        icon: 'fas fa-eye-slash',
        color: 'red-light',
        permissions: ['admin', 'customersManage'],
        showCondition: row => row.canViewRawData,
      },
      {
        name: 'disableCustomer',
        icon: 'fas fa-user-alt-slash',
        color: 'red-light',
        permissions: ['admin', 'customersManage'],
        showCondition: row => row.isEnabled,
      },
      {
        name: 'enableCustomer',
        icon: 'fas fa-user',
        color: 'blue',
        permissions: ['admin', 'customersManage'],
        showCondition: row => !row.isEnabled,
      },
      {
        name: 'showUsers',
        icon: 'fas fa-users',
        color: 'orange',
        permissions: ['admin', 'usersView'],
      },
    ],
  },
};

export default customersColumns;
