import Vue from 'vue';
import Element from 'element-ui';
import elemIt from 'element-ui/lib/locale/lang/it';
import locale from 'element-ui/lib/locale';
import moment from 'moment';
import { sync } from 'vuex-router-sync';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate/dist/vee-validate.full.esm';
import VueQuillEditor from 'vue-quill-editor';
import storage from 'store';

import it from 'vee-validate/dist/locale/it.json';
import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import globalFilters from './globalFilters';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';

// Install English and Italian vee-validate locales.
localize({
  it,
  en,
  es,
});
// Set starting vee-validate language.
localize(storage.get('locale') || 'it');
// moment default language
moment.locale('it');

sync(store, router);


// setting element ui language
locale.use(elemIt);
Vue.use(Element);
Vue.use(globalFilters);
Vue.use(VueQuillEditor, {
  modules: {
    toolbar: ['bold', 'italic', 'underline', 'strike', 'link', { list: 'ordered' }, { list: 'bullet' }, 'clean'],
    clipboard: {
      matchVisual: false,
    },
  },
});
Vue.config.productionTip = false;

// Global filters
Vue.prototype.$filters = Vue.options.filters;

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);


new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app');
