import Vue from 'vue';
import VueI18n from 'vue-i18n';
import storage from 'store';


import it from './it';
import en from './en';
import es from './es';

Vue.use(VueI18n);


const i18n = new VueI18n({
  locale: storage.get('locale') || 'it',
  messages: { it, en, es },
});

export default i18n;
