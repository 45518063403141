
import { NEWS_STATUSES_DESC } from '@/config/constants';

const newsFilters = [
  {
    name: 'publishedAt',
    label: 'publishedAt',
    type: 'dateDay',
  },
  {
    name: 'status',
    label: 'status',
    type: 'select',
    whereOperator: '=',
    options: Object.keys(NEWS_STATUSES_DESC)
      .map(key => ({ label: `enums.newsStatuses.${NEWS_STATUSES_DESC[key]}`, value: key })),
  },
];

export default newsFilters;
