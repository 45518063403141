import { SAMPLE_STATUSES, SAMPLE_STATUSES_DESC } from '@/config/constants';

const samplesColumns = {
  order: {
    key: 'orderTitle',
    label: 'orderName',
    type: 'text',
    sortable: false,
    minWidth: '150px',
  },
  sampleId: {
    key: 'id',
    label: 'sampleId',
    type: 'text',
    sortable: false,
    minWidth: '100px',
  },
  lot: {
    key: 'lot',
    label: 'lot',
    type: 'text',
    sortable: false,
    minWidth: '150px',
  },
  productName: {
    key: 'productName',
    label: 'productName',
    type: 'text',
    sortable: false,
    minWidth: '150px',
  },
  customerProductName: {
    key: 'customerProductName',
    label: 'customerProductName',
    type: 'text',
    sortable: false,
    minWidth: '150px',
  },
  reportsList: {
    key: 'reports',
    label: 'reportsList',
    type: 'slot-collapse-list',
    collapseTitle: 'samples.showReportsList',
    collapseKey: 'reports',
    collapseValue: 'laboratory',
    collapseAddon: 'status',
    collapseItemIcon: 'download',
    sortable: false,
    minWidth: '150px',
  },
  status: {
    key: 'status',
    label: 'status',
    type: 'encoded',
    minWidth: 100,
    align: 'center',
    sortable: false,
    customClass: true,
    enum: 'sampleStatuses',
    decode: value => SAMPLE_STATUSES_DESC[value],
    getClassModifier: value => SAMPLE_STATUSES_DESC[value],
  },
  rejectReason: {
    key: 'rejectReason',
    label: 'rejectReason',
    type: 'text',
    sortable: false,
    minWidth: '150px',
  },
  actions: {
    label: 'actions',
    type: 'actions',
    sortable: false,
    fixed: 'right',
    width: '165px',
    align: 'center',
    buttons: [
      {
        name: 'showAnalyses',
        icon: 'fas fa-eye',
        color: 'sand',
      },
      {
        name: 'editSample',
        icon: 'fas fa-pencil',
        color: 'blue-light',
        permissions: ['user', 'ordersManage'],
        showCondition: row => row.status === SAMPLE_STATUSES.CREATED,
      },
      {
        name: 'deleteSample',
        icon: 'fas fa-minus-circle',
        color: 'red-light',
        permissions: ['user', 'ordersManage'],
        showCondition: row => row.status === SAMPLE_STATUSES.CREATED,
      },
      {
        name: 'addAnalyses',
        icon: 'fas fa-plus',
        color: 'gray',
        permissions: ['user', 'ordersManage'],
        showCondition: row => row.status === SAMPLE_STATUSES.PENDING,
      },
      {
        name: 'renameSample',
        icon: 'fas fa-pencil',
        color: 'green',
        permissions: ['user', 'ordersManage'],
        showCondition: row => row.status === SAMPLE_STATUSES.DRAFT,
      },
      {
        name: 'downloadSampleReport',
        icon: 'fas fa-download',
        color: 'green',
        permissions: ['user', 'ordersManage'],
        showCondition: row => row.reports && row.reports.length > 1 && row.reports[0].id,
      },
    ],
  },
};

export default samplesColumns;
