import { customersFilters } from '@/config';
import tableHelper from '@/services/tableHelper';
import http from '../services/http';

const customers = {
  index(tableFilters, filterStatus) {
    const filters = tableHelper.whereFilters(filterStatus, customersFilters);
    return http.request({
      method: 'get',
      url: '/customers',
      params: { filter: { ...tableFilters, where: filters } },
    });
  },
  count(filterStatus) {
    const where = tableHelper.whereFilters(filterStatus, customersFilters);
    return http.request({
      method: 'get',
      url: '/customers/count',
      params: { where },
    });
  },
  show(id) {
    return http.request({
      method: 'get',
      url: `/customers/${id}`,
    });
  },
  create(data) {
    return http.request({
      method: 'post',
      url: '/customers',
      data,
    });
  },
  update(id, data) {
    return http.request({
      method: 'put',
      url: `/customers/${id}`,
      data,
    });
  },
  bulkUpdate(data, whereCondition) {
    return http.request({
      method: 'patch',
      url: '/customers',
      params: { where: whereCondition },
      data,
    });
  },
  patch(id, data) {
    return http.request({
      method: 'patch',
      url: `/customers/${id}`,
      data,
    });
  },
  autocompleteFetch(name) {
    const filter = {
      where: {
        name: { regexp: `/${name}/i` },
        or: [
          { expirationDate: { gt: new Date() } },
          { expirationDate: { eq: null } },
        ],
      },
    };
    return http.request({
      method: 'get',
      url: '/customers',
      params: { filter },
    });
  },
  findCustomerById(id) {
    try {
      return http.request({
        method: 'get',
        url: `/customers/${id}`,
      });
    } catch (e) {
      throw e;
    }
  },
  findCustomerByName(name) {
    const filter = {
      where: {
        name,
        limit: 1,
      },
    };
    return http.request({
      method: 'get',
      url: '/customers',
      params: { filter },
    });
  },
  findCustomersByName(name) {
    try {
      const filter = {
        where: {
          name: { regexp: `/${name}/i` },
          limit: 10,
        },
      };
      return http.request({
        method: 'get',
        url: '/customers',
        params: { filter },
      });
    } catch (e) {
      throw e;
    }
  },
  getCustomerAddresses(id) {
    return http.request({
      method: 'get',
      url: `/customers/${id}/addresses`,
    });
  },
  getCustomerAddress(id) {
    return http.request({
      method: 'get',
      url: `/customers/addresses/${id}`,
    });
  },
  createAddress(id, data) {
    return http.request({
      method: 'post',
      url: `/customers/${id}/addresses`,
      data,
    });
  },
};

export default customers;
