import tableHelper from '@/services/tableHelper';
import http from '../services/http';
import { usersFilters } from '../config';

const users = {
  async login(attributes) {
    try {
      const response = await http.request({
        method: 'post',
        url: '/users/login',
        data: attributes,
      });
      return response;
    } catch (e) {
      throw e;
    }
  },
  async resetPassword({ password, code }) {
    try {
      const response = await http.request({
        method: 'post',
        url: '/users/reset',
        params: { code },
        data: { password },
      });
      return response;
    } catch (e) {
      throw e;
    }
  },
  async recoverPassword(attributes) {
    try {
      const response = await http.request({
        method: 'post',
        url: '/users/recover',
        data: attributes,
      });
      return response;
    } catch (e) {
      throw e;
    }
  },
  getInfo() {
    return http.request({
      method: 'get',
      url: '/users/me',
    });
  },
  sendInvite(userId) {
    return http.request({
      method: 'post',
      url: '/users/invite',
      data: { id: userId },
    });
  },
  index(tableFilters, filterStatus) {
    const filters = tableHelper.whereFilters(filterStatus, usersFilters);
    // const includeCustomer = [{ relation: 'customer' }];
    try {
      return http.request({
        method: 'get',
        // eslint-disable-next-line
        // url: `/users?filter=${JSON.stringify({ ...tableFilters, include: includeCustomer, where: filters })}`,
        url: '/users',
        params: { filter: { ...tableFilters, where: filters } },
      });
    } catch (e) {
      throw e;
    }
  },
  count(filterStatus) {
    const where = tableHelper.whereFilters(filterStatus, usersFilters);
    try {
      return http.request({
        method: 'get',
        url: '/users/count',
        params: { where },
      });
    } catch (e) {
      throw e;
    }
  },
  show(id) {
    return http.request({
      method: 'get',
      url: `/users/${id}`,
    });
  },
  create(data) {
    return http.request({
      method: 'post',
      url: '/users',
      data,
    });
  },
  update(id, data) {
    return http.request({
      method: 'put',
      url: `/users/${id}`,
      data,
    });
  },
  bulkUpdate(data, whereCondition) {
    return http.request({
      method: 'patch',
      url: '/users',
      params: { where: whereCondition },
      data,
    });
  },
  patch(id, data) {
    return http.request({
      method: 'patch',
      url: `/users/${id}`,
      data,
    });
  },
};

export default users;
