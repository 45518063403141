import * as types from './mutation-types';

export const setReady = ({ commit }) => {
  commit(types.SET_READY);
};
export const setIsLoading = ({ commit }, isLoading) => {
  commit(types.SET_LOADING, isLoading);
};

export const parseQueryStringStatus = ({ dispatch }, query) => {
  // Sets filters status
  if (query.filterKey) {
    dispatch('applyFilters', {
      filterKey: query.filterKey,
      filterData: JSON.parse(query.filterData),
    });
  }
  if (query.tableKey && query.sorting) {
    dispatch('updateTableSorting', {
      tableKey: query.tableKey,
      sorting: JSON.parse(query.sorting),
    });
  }
  if (query.tableKey && query.perPage) {
    dispatch('updateTablePerPage', {
      tableKey: query.tableKey,
      perPage: parseInt(query.perPage, 10),
    });
  }
  if (query.tableKey && query.page) {
    dispatch('updateTableCurrentPage', {
      tableKey: query.tableKey,
      page: parseInt(query.page, 10),
    });
  }
};

export const resetStore = ({ dispatch }) => {
  dispatch('resetAllFilters');
  dispatch('resetAllTables');
};
