// import { offersApi } from '@/api';
import { ANALYSES_COMMODITY_CATEGORIES } from '@/config/constants';

const sampleAnalyses = [
  {
    name: 'docNumber',
    label: 'offerNumber',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'commodityCategory',
    label: 'testType',
    type: 'select',
    whereOperator: '=',
    options: [
      { label: 'enums.commodityCategory.chemical', value: ANALYSES_COMMODITY_CATEGORIES.CHEMICAL },
      { label: 'enums.commodityCategory.microbiological', value: ANALYSES_COMMODITY_CATEGORIES.MICROBIOLOGICAL },
    ],
  },
  {
    name: 'code',
    label: 'code',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'description',
    label: 'description',
    type: 'text',
    whereOperator: 'regexp',
  },
  {
    name: 'status',
    label: 'status',
    type: 'select',
    permissions: ['admin'],
    whereOperator: '=',
    options: [
      { label: 'filters.disabled', value: 0 },
      { label: 'filters.enabled', value: 1 },
    ],
  },
];

export default sampleAnalyses;
