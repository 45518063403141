import http from '@/services/http';

const version = {
  async getVersion() {
    return http.request({
      method: 'get',
      url: '/version',
    });
  },
};

export default version;
