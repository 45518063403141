import tableHelper from '@/services/tableHelper';
import { NEWS_STATUSES } from '@/config/constants';
import http from '../services/http';
import { newsFilters } from '../config';

const news = {
  index(tableFilters, filterStatus) {
    const filters = tableHelper.whereFilters(filterStatus, newsFilters);
    try {
      return http.request({
        method: 'get',
        url: '/news',
        params: { filter: { ...tableFilters, where: filters } },
      });
    } catch (e) {
      throw e;
    }
  },
  publishedIndex(tableFilters) {
    return http.request({
      method: 'get',
      url: '/news',
      params: {
        filter: {
          ...tableFilters,
          order: ['publishedAt desc'],
          where: {
            status: NEWS_STATUSES.PUBLISHED,
            publishedAt: { lte: new Date() },
          },
        },
      },
    });
  },
  publishedCount() {
    return http.request({
      method: 'get',
      url: '/news/count',
      params: {
        where: {
          status: NEWS_STATUSES.PUBLISHED,
          publishedAt: { lte: new Date() },
        },
      },
    });
  },
  count(filterStatus) {
    const where = tableHelper.whereFilters(filterStatus, newsFilters);
    return http.request({
      method: 'get',
      url: '/news/count',
      params: { where },
    });
  },
  show(id) {
    return http.request({
      method: 'get',
      url: `/news/${id}`,
    });
  },
  delete(id) {
    return http.request({
      method: 'delete',
      url: `/news/${id}`,
    });
  },
  create(data) {
    return http.request({
      method: 'post',
      url: '/news',
      data,
    });
  },
  update(data, id) {
    return http.request({
      method: 'put',
      url: `/news/${id}`,
      data,
    });
  },
  bulkUpdate(data, ids) {
    return http.request({
      method: 'patch',
      url: '/news',
      params: { where: { id: { inq: ids } } },
      data,
    });
  },
  downloadAttachment(newsId, fileId) {
    return http.request({
      method: 'get',
      url: `/news/${newsId}/attachments/${fileId}`,
      responseType: 'blob',
    });
  },
};

export default news;
