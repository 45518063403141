import { ORDER_STATUSES_DESC } from '@/config/constants';

const ordersFilters = [
  {
    name: 'creatorId',
    label: 'creatorId',
    type: 'text',
    whereOperator: '=',
    permissions: ['dev'],
  },
  {
    name: 'id',
    label: 'orderId',
    type: 'text',
    whereOperator: '=',
    permissions: ['admin'],
  },
  {
    name: 'id',
    label: 'orderConfirmId',
    type: 'text',
    whereOperator: '=',
    permissions: ['user'],
  },
  {
    name: 'title',
    label: 'order',
    type: 'text',
    whereOperator: 'regexp',
    permissions: ['admin'],
  },
  {
    name: 'title',
    label: 'orderName',
    type: 'text',
    whereOperator: 'regexp',
    permissions: ['user'],
  },
  {
    name: 'customerName',
    label: 'customerName',
    type: 'text',
    whereOperator: 'regexp',
    permissions: ['admin'],
  },
  {
    name: 'customerOrderNumber',
    label: 'customerOrderNumber',
    type: 'text',
    whereOperator: 'regexp',
    permissions: ['admin', 'user'],
  },
  {
    name: 'ondaOrderId',
    label: 'ondaOrderId',
    type: 'text',
    whereOperator: 'regexp',
    permissions: ['admin'],
  },
  {
    name: 'createdAt',
    label: 'createdAt',
    type: 'dateRange',
    permissions: ['admin'],
  },
  {
    name: 'status',
    label: 'status',
    type: 'select',
    permissions: ['admin', 'user'],
    whereOperator: '=',
    options: Object.keys(ORDER_STATUSES_DESC).filter(key => key !== 'draftCompleted' && key !== 'canceled')
      .map(key => ({ label: `enums.orderStatuses.${ORDER_STATUSES_DESC[key]}`, value: key })),
  },
];

export default ordersFilters;
