const offerItemsColumns = {
  analysisCode: {
    key: 'analysis.code',
    label: 'analysisCode',
    type: 'text',
    sortable: false,
  },
  description: {
    key: 'analysis.description',
    label: 'customDescription',
    type: 'text',
    sortable: false,
  },
  childrenCount: {
    key: 'analysis',
    label: 'childrenCount',
    type: 'function',
    minWidth: 40,
    get: value => value.children && value.children.length,
  },
  methodCode: {
    key: 'analysis.method',
    label: 'method',
    type: 'text',
    sortable: false,
  },
  quantity: {
    key: 'quantity',
    label: 'quantity',
    type: 'text',
    sortable: false,
  },
};

export default offerItemsColumns;
