const invoicesColumns = {
  invoiceNumber: {
    key: 'invoiceNumber',
    label: 'invoiceNumber',
    type: 'text',
    sortable: false,
  },
  invoiceDate: {
    key: 'invoiceDate',
    label: 'createdAt',
    type: 'date',
    sortable: 'custom',
  },
  orderNumber: {
    key: 'orderNumber',
    label: 'orderNumber',
    type: 'text',
    sortable: false,
  },
  actions: {
    label: 'actions',
    type: 'actions',
    sortable: false,
    minWidth: 100,
    buttons: [
      {
        name: 'showInvoice',
        icon: 'fas fa-file-pdf',
        color: 'blue-light',
        showCondition: row => row.invoicePdfId,
      },
      {
        name: 'showAttachment',
        icon: 'fas fa-file-pdf',
        color: 'red-light',
        showCondition: row => row.attachmentPdfId,
      },
    ],
  },
};

export default invoicesColumns;
