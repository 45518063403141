const menuItems = [
  {
    name: 'newsUserIndex', // vue-router name
    icon: 'fas fa-newspaper', // icon class
  },
  {
    name: 'customersIndex',
    icon: 'fas fa-building',
  },
  {
    name: 'requestOffer',
    icon: 'fa fa-user-tie',
  },
  {
    name: 'offersIndex',
    icon: 'fas fa-list-ul',
  },
  {
    name: 'newsAdminIndex',
    icon: 'fas fa-newspaper',
  },
  {
    name: 'ordersIndex',
    icon: 'fas fa-folder-open',
  },
  {
    name: 'samplesIndex',
    icon: 'fas fa-chart-network',
  },
  {
    name: 'invoicesIndex',
    icon: 'fas fa-file-invoice',
  },
  // {
  //   name: 'analysesIndex',
  //   icon: 'fas fa-microscope',
  // },
  {
    name: 'usersIndex',
    icon: 'fas fa-users',
  },
];

export default menuItems;
