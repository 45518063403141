const sampleAnalysesColumns = {
  commodityCategory: {
    key: 'commodityCategory',
    label: 'testType',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
  },
  docNumber: {
    key: 'docNumber',
    label: 'offerNumber',
    type: 'text',
    sortable: 'custom',
    minWidth: 170,
  },
  code: {
    key: 'code',
    label: 'code',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
  },
  description: {
    key: 'description',
    label: 'description',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
  },
  extraDescription: {
    key: 'extraDescription',
    label: 'additionalDescription',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
  },
  method: {
    key: 'method',
    label: 'method',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
  },
  accreditedTest: {
    key: 'accreditedTest',
    label: 'accreditedTest',
    type: 'encoded',
    sortable: 'custom',
    minWidth: 200,
    enum: 'commons',
    decode: value => (value ? 'yes' : 'no'),
  },
  remaining: {
    key: 'remaining',
    label: 'remaining',
    type: 'text',
    sortable: 'custom',
    minWidth: 200,
    help: 'remainingAnalyses',
  },
};

export default sampleAnalysesColumns;
