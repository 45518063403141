import * as types from '../mutation-types';

// const TEST_PERMISSIONS = [
//   'admin',
//   'newsView',
//   'newsManage',
//   'offersView',
//   'offersManage',
//   'ordersView',
//   'ordersManage',
//   'samplesStatusView',
//   'samplesStatusManage',
//   'billingView',
//   'billingManage',
//   'usersView',
//   'usersManage',
//   'customersView',
//   'customersManage',
// ];

const initialState = {
  loggedUser: null,
};

const usersState = { ...initialState };

// getters
const usersGetters = {
  loggedUser: state => state.loggedUser,
  userPermissions: (state) => {
    if (state.loggedUser) {
      // const permissions = [...TEST_PERMISSIONS]; // Dev
      const permissions = [...state.loggedUser.permissions];
      // Adds a fake permission to deal with user only resources
      if (!permissions.includes('admin')) permissions.push('user');
      return permissions;
    }
    return null;
  },
  canViewRawData: (state) => {
    if (state.loggedUser && state.loggedUser.customer) {
      return state.loggedUser.customer.canViewRawData;
    }
    return true; // no customer associated to user, enable raw data as default.
  },
};

// actions
const actions = {
  setLoggedUser({ commit }, user) {
    commit(types.SET_USER, user);
  },
};

const mutations = {
  [types.SET_USER](state, user) {
    state.loggedUser = user;
  },
};

export default {
  state: usersState,
  getters: usersGetters,
  actions,
  mutations,
};
